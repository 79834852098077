/* /* .sidemenuContainer {
  width: 295px;
  box-shadow: 0px 0px 0px 0px #c2c2c2;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.selectSection {
  background-color: rgba(220, 220, 220, 0.3);
  
}

.rightmenucontainer {
  padding: "2 rem";
  border-color: #6ea9ff;
  width: 100%;
} */
/* .profileLeftContainer {
  width: 295px;
  box-shadow: 0px 0px 8px 0px #c2c2c2;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}
.calcRightContainer {
  margin-top: 20px;
  width: 1000px;

  height: 410px;
  flex-shrink: 0;
  border-radius: 19px;
  border: 1px solid #6ea9ff;
  background: #fdfdfd;
  padding: 2rem;
}

.calcRightContainercvd {
  margin-top: 20px;
  width: 1000px;

  height: 610px;
  flex-shrink: 0;
  border-radius: 19px;
  border: 1px solid #6ea9ff;
  background: #fdfdfd;
  padding: 2rem;
} */

/* .BmiCalculatorHeading {
  color: #52447d;
  font-family: Varela Round;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.32px;
  padding: "2px";
  margin-left: 50px;
}

.buttonstyles {
  border-radius: "8px";
  background: "#F0EAFF";
    margin-top: 12px;
  
  display: flex;
  margin-right: 10px !important;
  gap:20px;
}

.buttonstyles input {
  border: none;
  outline: none;
  background: #f0eaff;
   width: 200px; 
   height: 48px; 
  padding: 1rem;
  top: 373px;
  left: 415px;
  border-radius: 8px;
}

.hwrow {
  display: flex;
}

.calcsection {
  display: flex;
} */

/* .calculateButton{
  margin: 2rem 0;
  text-align: top;
 
    gap: 1rem;
}
.cvdCalculateButtonCalculate{
    display: flex;
    align-items: center;
    background: #E1D7FF;
     padding: 0.8rem 5.6rem; 
    border-radius: 8px;
    gap: 0.25rem;
    margin: 2rem 0;
    color: #fff;
    text-align: center;
    font-family: Varela Round;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 294px;
    height: 50px;
    flex-shrink: 0;
   
    background: #52447d;
    border: none;
  
} */
/* .genderageclasscvd{

  gap: 35px;
 
  display: flex;
}
.CalculateButtonCalculate{
  color: #fff;
  text-align: center;
  font-family: Varela Round;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 148px;
  height: 19px;
  flex-shrink: 0;
  width: 294px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 7px;
  background: #52447d;
  border: none;
 
}

.resultButton {
 
 
  flex-shrink: 0;
  color: #52447d;
  text-align: center;
  font-family: Varela Round;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 91%; 
  display: flex;
  align-items: center;

}
.resultButton p {
  width: 100%;
 
  flex-shrink: 0;
  border-radius: 8px;
  border: none;
}
.bmiresultcontainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.radioButtons {
  display: flex;
  margin-top: 20px !important;
  margin-right: 50px !important;
  
  font-family: Varela Round;
  font-size: 21px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.005em;
  text-align: left;
  padding-bottom: 0rem;
  margin-left: 40px;
}

.radiobuttonlabel {
  padding: 0 10px;
  display: flex;
  gap: 0.75rem;
  font-size: 18px;
}

.weightscontent {
  width: 442px;
  height: 22px;
  flex-shrink: 0;
  color: #52447d;
  text-align: center;
  font-family: Varela Round;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 91%; 
  letter-spacing: -0.105px;
}
.idealweight {
  width: 950px;
  border-radius:15px;
  flex-shrink: 0;
  color: #52447d;
  text-align: center;
  font-family: Varela Round;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 270%;
  letter-spacing: -0.105px;
  background-color:#C0FFD2;

  
} */

/* cvdcss */
/* .cvdriskgender{
  width: 377px;
height: 48px;
top: 364px;
left: 416px;
border-radius: 8px;
margin-left: 3px;

}
.cvdriskscorecalciHeading{
font-size: 36px;
    
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.32px;
    margin-left: 3px;
    color: #52447d;
}
.cvdradioButtons{
  display: flex;
    margin-top: 22px !important;
    margin-right: 50px !important;
    font-family: Varela Round;
    font-size: 21px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.005rem;
    text-align: left;
    margin-bottom: 1.5rem; 
    margin-left: 7px;
}
.cvdradiobuttonlabel{
   
    padding: 0 10px;
    display: flex;
    gap: 0.75rem;
    font-size: 18px; 
}
  
  .cvdgenderbox  {
   
      border: none;
      outline: none;
      background: #f0eaff;
      padding: 1 rem;
      top: 373px;
      left: 415px;
      margin: 2 0 5 0 px;
      border-radius: 8px;    
    }
  .agebox{
    border: none;
    outline: none;
    background: #f0eaff;
    padding: 1rem;
     /* top: 373px; */
/* left: 500px; 
    border-radius: 8px;
  } */

/* .cholestrolmmmg{
    background: "#F0EAFF";
    display: flex;
    display: flex;
    gap: 4px;
    align-content: flex-start;
    align-items: stretch;
    flex-direction: column;
  } */
/* 
  .cholestrolmmmg input{
    border: none;
    outline: none;
    background: #f0eaff;
    width: 100px;
    height: 50px;
    padding: 10px;
    margin: 5px;
    top: 373px;
    left: 10px;
    border-radius: 8px;
    margin-top: 10px;
  }
  .labresultbuttons{
    display: flex;
    flex-direction: column;
  }
  .nonlabresultsbuttons{
    display: flex;
    flex-direction: column;
  }
  .DiabeteRiskAssesmentHeading{
    font-size: 36px;
    
    font-weight: 400;
    line-height: normal;
    letter-spacing: 4.32px;
    margin-left: 3px;
    color: #52447d;
  }


  /* row container for diabetes risk assesssment  */

.row-container1 {
  display: flex;
}

.diabetesriskage {
  border: none;
  outline: none;
  background: #f0eaff;
  padding: 1 rem;
  top: 373px;
  left: 415px;
  margin: 2 0 5 0 px;
  border-radius: 8px;
  height: 35px;
  display: "flex";
  flex-direction: "row";
  align-items: "center";
  margin-right: 10px;
  width: 100px;
}

.diabetesriskbmirange {
  border: none;
  outline: none;
  background: #f0eaff;
  padding: 1 rem;
  top: 373px;
  left: 415px;
  margin: 2 0 5 0 px;
  border-radius: 8px;
  height: 35px;
  flex-direction: row;
  width: 130px;
  margin-left: 10px;
}

.diabetesgenderage {
  border: none;
  outline: none;
  background: #f0eaff;
  padding: 1 rem;
  top: 373px;
  left: 415px;
  margin: 2 0 5 0 px;
  border-radius: 8px;
}

.diabetesphysicalactivitieslabel {
  display: flex;
}

.diabetesCalculateButtonCalculate {
  display: flex;
  align-items: center;
  background: #e1d7ff;
  padding: 0.8rem 2.6rem;
  border-radius: 8px;
  gap: 0.25rem;
  margin: 2rem 0;
  color: #fff;
  text-align: center;
  font-family: Varela Round;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 39px;
  flex-shrink: 0;
  background: #52447d;
  border: none;
  white-space: nowrap;
}

.sidemenuContainer {
  background: #ffffff;
  display: flex;
  /* height: 70vh; */
}

.selectSection {
  background-color: rgba(220, 220, 220, 0.3);
  border-left: 6px solid rgb(82, 68, 125);

}

.rightmenucontainer {
  padding: "2 rem";
  border-color: #6ea9ff;
  width: 100%;
}

/* .profileLeftContainer {
    width: 295px;
    box-shadow: 0px 0px 8px 0px #c2c2c2;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 2rem;
  } */

.calcRightContainer {
  width: 80%;
  border-radius: 19px;
  border: 1px solid rgba(253, 251, 255, 1);
  background: #fdfdfd;
  margin: 1% 1% 2%;
  overflow: scroll;
  padding: 2px;
}

.calcRightContainercvd {
  width: 65%;
  flex-shrink: 0;
  border-radius: 19px;
  border: 1px solid #6ea9ff;
  background: #fdfdfd;
  margin: 2% 1% 2%;
  flex: 2.38;
  overflow: scroll;
}

.calcRightContainercvd input {
  width: 30% !important;
}

.BmiCalculatorHeading {
  color: #52447d;
  font-family: Varela Round;
  font-size: 19.2px;
  font-weight: 400;
  padding: 2%;
  font-weight: 400;
}

.HeadingBmiCalculator {
  display: flex;
  background-color: rgba(234, 236, 255, 1);
  border-radius: 19px 19px 0px 0px;
}

.type select {
  border: solid 1px #acc5ea;
  padding: 5px;
  width: 14%;
}

.buttonstyles input {
  display: flex;
  flex-direction: column;
  /* gap: 4%; */
  width: 13rem !important;
  border: none;
  outline: none;
  height: 40px;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(70, 95, 241, 0.4);
}

.buttonstylesCVD input {
  display: flex;
  flex-direction: column;
  /* margin-right: 10px !important; */
  gap: 4%;
  width: 92% !important;
  border: none;
  outline: none;
  width: 175px;
  height: 10px;
  padding: 1rem;
  border-radius: 8px;
  /* margin-top: 0.5rem; */
  border: 1px solid rgba(70, 95, 241, 0.4);
}

.hwrow {
  display: flex;
  gap: 5%;
  margin-top: 2%;
}

.calcsection {
  display: flex;
  height: 80vh;
  /* overflow: hidden; */
  flex-direction: column;
  width: 100%;
  background-color: white;
  /* border: 1px solid rgba(255, 255, 255, 1); */
  border-radius: 10px;
  /* padding: 2% 1% 1% 2%; */
}

.CalculatorContainer {
  display: flex;
  height: 76vh;
}

.MainHeadingCalculator {
  margin: 0% 0% 0% 2%;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 27px;
  letter-spacing: 3px;
  color: rgba(9, 74, 115, 1);
  padding: 1%;
}



.calculatorIteams {
  font-size: 1rem;
  font-weight: 500 !important;
  line-height: 21px !important;
  color: rgba(82, 68, 125, 1);
  letter-spacing: 0rem !important;
}

.calculateButton {
  margin: 2rem 0;
  text-align: top;
  margin-bottom: 1rem;

  gap: 1rem;
}

.cvdCalculateButtonCalculate {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Ensures text is centered */
  background: #52447d;
  padding: 10px 15px;
  border-radius: 8px;
  gap: 8px;
  /* More even spacing */
  margin: 0;
  color: #fff;
  text-align: center;
  font-family: "Varela Round", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  height: 38px;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
  /* Makes it clear it's clickable */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cvdCalculateButtonCalculate:hover {
  background: #52447d;
  /* Slightly lighter shade for hover */
  transform: scale(1.05);
  /* Subtle zoom effect */
}

.genderageclasscvd {
  gap: 4%;
  /* padding:3rem; */
  display: flex;
  /* width: 80%; */
  border-radius: 10px;
  align-items: center;
}

.CalculateButtonCalculate {
  color: #fff;
  text-align: center;
  font-family: Varela Round;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 148px;
  height: 19px;
  flex-shrink: 0;
  width: 294px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 7px;
  background: #52447d;
  border: none;
  /* margin-left: 250px; */
}

.resultButton {
  /* width: 442px; */

  flex-shrink: 0;
  color: #52447d;
  text-align: center;
  font-family: Varela Round;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 91%;
  /* 19.11px */
  display: flex;
  align-items: center;
  /* background-color: red; */
}

.resultButton p {
  /* width: 100%; */
  margin-left: 5px;

  /* height: 52px; */
  flex-shrink: 0;
  border-radius: 8px;
  border: none;
}

.bmiresultcontainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.radioButtons {
  display: flex;
  /* margin-top: 20px !important; */
  margin-right: 50px !important;
  font-family: Varela Round;
  font-size: 21px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.005em;
  text-align: left;
  padding-bottom: 0rem;
  gap: 5%;
}

.group-radiobuttons {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  /* margin: 0 auto; */
  border-radius: 50px;
  box-shadow: 0px 1px 4px 0px #00000040;
  width: 300px;
  height: 45px;
}

.group-radiobuttons-input {
  appearance: none;
}

.group-radiobuttons>.activeSection {
  background-color: #52447d;
  color: #fff;
  height: 45px;
  border-radius: 8px;
}

.group-radiobuttons-label {
  width: 150px;
  text-align: center;
  align-content: center;
}

.radiobuttonlabel {
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  /* font-size: 18px; */
}

/* .radiobuttonlabel input {
  width: 25px;
  height: 16px;
} */

.weightscontent {
  width: 442px;
  height: 22px;
  flex-shrink: 0;
  color: #52447d;
  text-align: center;
  font-family: Varela Round;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 91%;
  /* 19.11px */
  letter-spacing: -0.105px;
}

.idealweight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Centers content vertically */
  align-items: center;
  /* Centers content horizontally */
  max-width: 600px;
  /* Sets a comfortable width for desktop */
  width: 100%;
  border-radius: 8px;
  flex-shrink: 0;
  color: #52447d;
  text-align: center;
  font-family: "Varela Round", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: -0.105px;
  background-color: #c0ffd2;
  padding: 20px;
  /* Adds spacing inside */
  margin: 20px auto;
  /* Centers the container horizontally */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* Adds a subtle shadow */
}

/* cvdcss */
.cvdriskgender {
  width: 377px;
  height: 48px;
  top: 364px;
  left: 416px;
  border-radius: 8px;
  margin-left: 3px;
}

.cvdriskscorecalciHeading {
  font-size: 36px;

  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.32px;
  margin-left: 3px;
  color: #52447d;
}

.cvdradioButtons {
  display: flex;
  /* margin-right: 50px !important; */
  font-family: Varela Round;
  font-size: 21px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.005rem;
  text-align: left;
  align-items: center;
  margin-bottom: 10px;
  /* margin-bottom: 1.5rem;
  margin-left: 7px;
  margin-top: 5%; */
}

.cvdradiobuttonlabel {
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 18px;
}

.cvdgenderbox {
  border: none;
  outline: none;
  background: #f0eaff;
  padding: 1 rem;
  top: 373px;
  left: 415px;
  margin: 2 0 5 0 px;
  border-radius: 8px;
  width: 25%;
}

.agebox {
  border: none;
  outline: none;
  /* background: #f0eaff; */
  padding: 1rem;
  /* top: 373px; */
  left: 500px;
  border-radius: 8px;
}

.output-section {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

}

.cholestrolmmmg {
  background: "#F0EAFF";
  display: flex;
  display: flex;
  gap: 4px;
  align-content: flex-start;
  align-items: stretch;
  flex-direction: column;
}

.cholestrolmmmg input {
  border: none;
  outline: none;
  background: #f0eaff;
  width: 100px;
  height: 50px;
  padding: 10px;
  margin: 5px;
  top: 373px;
  left: 10px;
  border-radius: 8px;
  margin-top: 10px;
}

.labresultbuttons {
  width: 40%;
  padding: 1%;
  border-radius: 10px;
  border: none;
  text-align: left;
  margin-bottom: 2%;
  background-color: #ffffff;
}

.diabetesCalculateButtonCalculateinnerall {
  display: flex;
  gap: 5px;
  background-color: rgb(192, 255, 210);
  padding: 2%;
  border-radius: 10px;
  flex-direction: column;
}

.diabetesCalculateButtonCalculateinner {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.diabetesCalculateButtonCalculateinner p {
  padding: 1% 2%;
  border: none;
  border-radius: 15px;
  /* margin-bottom: 2%; */
  background-color: white;
}

.diabetesCalculateButtonCalculatemesage p {
  padding: 2% 2%;
  border: none;
  border-radius: 15px;
  background-color: #fcc96c;
  margin: 1%;
  /* margin-bottom: 25; */
}

.labresultbuttonscvd {
  width: 45%;
  padding: 1%;
  border-radius: 10px;
  border: 2px solid #565151;
  text-align: left;
  /* margin-bottom: 2%; */
  display: flex;
  background-color: #c0ffd2;

  color: #52447d;
  gap: 5%;
}

.nonlabresultsbuttons {
  display: flex;
  flex-direction: column;
}

.DiabeteRiskAssesmentHeading {
  font-size: 36px;

  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.32px;
  margin-left: 3px;
  color: #52447d;
}

/* row container for diabetes risk assesssment  */
.row-container {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  gap: 10px;
}

/* .row-containerinner {
  display: flex !important;
  width: 30%;
  gap: 5%;
  align-items: center;
  white-space: nowrap;
} */

.row-container1 {
  display: flex;
  justify-content: space-between;
  gap: 3%;
  /* margin-top: 2%; */
  flex-direction: column;
}

.container1in {
  width: "45%";
  display: flex;
}

/* .row-container1 label {
  width: 65%;
} */

.row-container1 select {
  width: 33%;
}

/* .diabetesriskage,
#maleWaistDropdown,
#femaleWaistDropdown {
  border: none;
  outline: none;
  background: #f0eaff;
  padding: 1 rem;
  top: 373px;
  left: 415px;
  margin: 2 0 5 0 px;
  border-radius: 8px;
  height: 35px;
  display: "flex";
  flex-direction: "row";
  align-items: "center";
  margin-right: 10px;
} */
.diabetesriskbmirange {
  border: none;
  outline: none;
  background: #f0eaff;
  padding: 1 rem;
  top: 373px;
  left: 415px;
  margin: 2 0 5 0 px;
  border-radius: 8px;
  height: 35px;
  flex-direction: row;
  width: 100px;
  margin-left: 10px;
}

.diabetesgenderage {
  width: 10%;
  height: 35px;
  border: none;
  outline: none;
  background: #f0eaff;
  padding: 1 rem;
  top: 373px;
  left: 415px;
  margin: 2 0 5 0 px;
  border-radius: 8px;
}

.diabetesphysicalactivities {
  border: none;
  outline: none;
  /* background: #f0eaff; */
  padding: 1 rem;
  top: 373px;
  left: 415px;
  margin: 2 0 5 0 px;
  border-radius: 8px;
  height: 40px;
}

.diabetesphysicalactivitieslabel {
  display: flex;
}

.diabetesvegetablefreq {
  border: none;
  outline: none;
  /* background: #f0eaff; */
  padding: 1 rem;
  top: 373px;
  left: 415px;
  margin: 2 0 5 0 px;
  border-radius: 8px;
  height: 40px;
}

.diabetesmedichighbp {
  border: none;
  outline: none;
  /* background: #f0eaff; */
  padding: 1 rem;
  top: 373px;
  left: 415px;
  margin: 2 0 5 0 px;
  border-radius: 8px;
  height: 40px;
}

.diabeteshighsugar {
  border: none;
  outline: none;
  /* background: #f0eaff; */
  padding: 1 rem;
  top: 373px;
  left: 415px;
  margin: 2 0 5 0 px;
  border-radius: 8px;
  height: 40px;
}

.familyDBDiagnosisDropdown {
  border: none;
  outline: none;
  /* background: #f0eaff; */
  padding: 1 rem;
  top: 373px;
  left: 415px;
  margin: 2 0 5 0 px;
  border-radius: 8px;
  height: 35px;
}

.diabetesfamilydiagnos {
  border: none;
  margin-left: 4px;
  outline: none;
  /* background: #f0eaff; */
  padding: 1 rem;
  top: 373px;
  left: 415px;
  margin: 2 0 5 0 px;
  border-radius: 8px;
  height: 40px;
  width: 33%;
}

/* .sectionsName{
    border-left: 6px solid rgb(82, 68, 125);
    background: rgba(220, 220, 220, 0.3);
  } */

/* CvdScore css some part*/
.CalBtnPosition {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: 2%;
  flex-direction: column;
}

.Labriskbgaftercal {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  background-color: #c0ffd2;
  padding: 2%;
  border-radius: 10px;
}

.textparagsize {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.DiabetiescalBtn {
  width: 100%;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

/*New design*/
.calculator-MainContainer {
  display: flex;
}

.BMIresult {
  display: flex;
  align-items: center;
  background-color: #e1d7ff;
  border-radius: 8px;
  gap: 0.25rem;
  padding: 1%;
}

/* .row-container select {
  border: solid 1px #acc5ea !important;
  padding: 5px !important;
  width: 100px !important;
} */

.buttonstyles {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Diabetesrow-containerinner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 16rem;
  margin: 5px;
}

.waistManpulation {
  display: flex;
  margin-bottom: 2%;
}

.cvdSelectTag {
  width: 13rem;
  padding: 4%;
  border: 1px solid rgba(70, 95, 241, 0.4);
  border-radius: 8px;
}

.cvdradioButton {
  display: flex;
  flex-direction: row;
  /* margin-right: 10px !important; */
  gap: 4%;
  /* width: 92% !important; */
  border: none;
  outline: none;
  /* width: 175px; */
  height: 10px;
  /* padding: 1rem; */
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  /* border: 1px solid rgba(70, 95, 241, 0.4); */
  align-items: center;
}

.ButtonCVD input {
  display: flex;
  flex-direction: column;
  /* margin-right: 10px !important; */
  gap: 4%;
  width: 13rem !important;
  border: none;
  outline: none;
  width: 175px;
  height: 10px;
  padding: 1rem;
  border-radius: 8px;
  /* margin-top: 0.5rem; */
  border: 1px solid rgba(70, 95, 241, 0.4);
}

.SelectTagcvd {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.CalculatorLabel {
  color: rgba(82, 68, 125, 1);
}

@media (max-width: 1290px) {
  .hwrow {
    display: flex;
    flex-direction: column;
    gap: 5%;
    margin-top: 2%;
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .ButtonCVD input {
    width: 9rem !important;
  }

  .CalculatorContainer input {
    /* width: 18px !important;
    height: 14px !important; */
  }

  .buttonstyles input {
    width: 60% !important;
  }

  .CalculateButtonCalculate {
    color: #fff;
    text-align: center;
    font-family: Varela Round;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100px !important;
    height: 19px;
    flex-shrink: 0;
    width: 294px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 7px;
    background: #52447d;
    border: none;
    /* margin-left: 250px; */
  }

  .BMIresult {
    display: flex;
    align-items: center;
    background-color: #e1d7ff;
    border-radius: 8px;
    gap: 0.25rem;
    flex-direction: column;
    gap: 10px;
  }

  .sectionsName {
    padding: 0.2rem 0.5rem !important;
    gap: 0px !important;
  }

  .calculatorIteams {
    font-size: 0.5rem;
  }

  .agebox {
    border: none;
    outline: none;
    padding: 1rem;
    left: 500px;
    border-radius: 8px;
    width: 17% !important;
  }

  .row-containerinner {
    display: flex !important;
    width: 20%;
    gap: 5%;
    align-items: center;
    white-space: nowrap;
  }

  .Diabetesrow-containerinner {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 9rem;
    margin: 5px;
  }
}