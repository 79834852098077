.Vidtitle {
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 26px;
  color: #000000;
  padding: 1px 4px;
  font-family: "Inter";
}

.videoPlayerContainer {
  padding-top: 10%;
  padding-left: 4%;
}

.vidLeftCont {
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: scroll;
}

.videoItem {
  display: flex;
  background: #eeecf5;
  border-radius: 9px;
  padding: 15px 20px
}

.videocontainer {
  border: none;
  border-radius: 16px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: -32px;
}

.Loading {
  font-size: 40px;
  padding-top: 100%;
  /* padding-right: 70%; */
  padding-left: 80%;
}