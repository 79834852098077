@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,800;1,700&family=Open+Sans:wght@400;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,800;1,200;1,400;1,500;1,700;1,900&family=Telex&family=Viga&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  font-family: Telex !important;
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Varela Round", sans-serif; */
}

/* @import "@fontsource/varela-round";
body {
  font-family: "Varela Round";
} */

@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #8873c9 !important;
  font-size: 14px;
  padding: 1%;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

.graphContainer {
  background-color: #0b4876;
  gap: 3rem;
  margin-left: 7%;
  margin-right: 7%;
  padding: 2rem;
  border-radius: 10px;
  margin-top: 2%;
}

.graphHeader {
  color: #0b4876;
  text-align: left;
  margin-left: 7%;
}

/* for Loading  */
.spinningLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  height: 50vh;
  color: #52447d;
}

.spinningLoader2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(228, 228, 228, 0.3);
  backdrop-filter: blur(5px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}

.spinningLoader1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #e4e4e44f; */
  background-size: cover;
  height: 80vh;
}