.side-navHeader {
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  color: #52447d;
  width: 250px;
  z-index: 0;
}

.side-navHeader {
  transition: width 0.3s ease-in-out;
  /* Adjust the duration and timing function as needed */
}


h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.side-navItems {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 99vh;
  transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* Smoother transition */
}

.logoContainer,
.sideNavTopMenu,
.sideNavBottomMenu {
  transition: width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* Matching smoothness */
}


/* .side-navItems {
  width: 92%;
} */

.logo {
  width: 100px;
  height: 100px;
  margin-top: 10px;
}

.navItemLink {
  display: flex;
}

.buttonContainer.active,
.buttonContainersmall .active {
  display: flex;
  background-color: #fbf8ff;
  width: 100%;
  justify-content: space-between;
}


.navItemIcon {
  width: 30px;
  margin-right: 15px;
  margin-left: 20px;
}

.toggle-button.active {
  border-radius: 30px;
  font-size: 1.1rem;
  transition: all 0.2s ease-in-out;
  outline: none;
  line-height: 18px;
  color: rgba(82, 68, 125, 1);
}

.toggle-button {
  cursor: pointer;
  background: transparent;
  font-size: 1.1rem;
  font-weight: 400;
  color: rgba(9, 74, 115, 1);
  border: 1px solid transparent;
  box-sizing: content-box;
  z-index: 5;
  outline: none;
  white-space: nowrap;
  line-height: 1px;
}

.small-navbar-icon {
  justify-content: center !important;
  width: 100%;
}

/* .buttonContainer, */
.buttonContainersmall {
  display: flex;
  flex-direction: row;
  /* text-align: center;
  justify-content: center;
  align-items: center; */
  justify-content: flex-start;
  width: 230px;
  padding: 10px 0px;
  width: 107%;
  color:
    rgba(186, 186, 186, 1);
  /* align-items: center; */
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 230px;
  padding: 11px 0px;
  width: 107%;
  color: rgba(186, 186, 186, 1);
}

.navItemIconContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 5px;
}

.navItemIconContainer:hover {
  transform: scale(1.05);
}

.small-text {
  display: none;

  font-size: 12px;

  color: rgba(82, 68, 125, 1);

  white-space: nowrap;
}

/* .small-navbar-icon:hover .small-text {
  display: inline;

} */

.underline {
  color: #52447d;
  border-right: 3px solid blueviolet;
  /* width: 20px; */
  /* border-radius: 1.5rem; */
}


.sideNavMenu {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 110px;
}

/* .logout-menu {
  color: red;
} */

.profileicon {
  margin-left: 15px;
  padding-right: 15px;
}




.logoContainer {
  display: flex;
  padding-bottom: 15%;
  flex-direction: column;
  width: 100%;
}

.logoToggle {
  border: none;
  background: none;
  margin: 20% 0;
}

.logo-small {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.logo-large {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.sideNavTopMenu {
  display: flex;
  flex-direction: column;
}

.RightArrow {
  height: 27px;
  background: #e6dcdc;
  width: 27px;
  position: absolute;
  margin-left: 12%;
  border-radius: 50%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.leftArrow {
  height: 27px;
  background: #e6dcdc;
  width: 27px;
  font-weight: bold;
  /* right: 0; */
  position: absolute;
  margin-left: 3.7%;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logo-small,
.logo-large {
  transition: all 1s ease;
}

@media screen and (max-width: 1236px) {
  .toggle-button {
    /* padding: 7px 10px; */
    cursor: pointer;
    background: transparent;
    font-size: 1rem;
    font-weight: 400;
    color: rgba(186, 186, 186, 1);
    border: 1px solid transparent;
    box-sizing: content-box;
    z-index: 5;
    outline: none;
    white-space: nowrap;
    line-height: 1px;
    /* margin-left: 8%; */
  }

  .toggle-button.active {
    border-radius: 30px;
    font-size: 1.1rem;
    transition: all 0.2s ease-in-out;
    outline: none;
    line-height: 18px;
    color: rgba(82, 68, 125, 1);
  }

  .navItemIcon {
    width: 18px;
    margin-right: 0px;
    margin-left: 7px;
  }

  .underline {
    color: #52447d;
    border-right: 3px solid blueviolet;
    /* width: 20px; */
    /* border-radius: 1.5rem; */
  }

  .logo-large {
    width: 70px;
    height: 70px;
    cursor: pointer;

  }
}

@media screen and (max-width: 1000px) {
  .toggle-button {
    /* padding: 7px 10px; */
    cursor: pointer;
    background: transparent;
    /* font-size: 9px; */
    font-weight: 400;
    color: rgba(186, 186, 186, 1);
    border: 1px solid transparent;
    box-sizing: content-box;
    z-index: 5;
    outline: none;
    white-space: nowrap;
    line-height: 1px;
    font-size: 11px;
    margin-left: 7px;
  }

  .toggle-button.active {
    border-radius: 30px;
    transition: all 0.2s ease-in-out;
    outline: none;
    line-height: 18px;
    color: rgba(82, 68, 125, 1);
    /* font-size: 9px; */
    font-size: 11px;
    margin-left: 7px;
  }

  .navItemIcon {
    width: 16px;
    margin-right: 0px;
    margin-left: 5px;
  }

  .underline {
    color: #52447d;
    border-right: 3px solid blueviolet;
    /* width: 20px; */
    /* border-radius: 1.5rem; */
  }

  .logo-large {
    width: 70px;
    height: 70px;
    cursor: pointer;

  }
}

@media screen and (max-width: 1235px) {
  .buttonContainersmall .active {
    display: flex;
    background-color: #fbf8ff;
    width: 100%;
    justify-content: center;
  }

  /* .buttonContainersmall {
  display: flex;
  background-color: #fbf8ff;
  width: 100%;
  justify-content: center;
} */
}