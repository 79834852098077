.articlesection {
  display: flex;
  border-radius: 45px;
  color: #52447d;
  width: 100%;
  justify-content: space-between;
}

.digitalhealthheader {
  display: flex;
  margin: 2rem;
  gap: 85px;
  /* background: #eae8ff; */
  border-radius: 30px;
  padding: 2rem 5rem 0rem 5rem;
  justify-content: space-between;
  color: rgba(82, 68, 125, 1);
  font: Varela Round;
}

@media (max-width: 822px) {
  .digitalhealthheader {
    display: flex;
    margin: 1rem;
    gap: 45px;
    /* background: #eae8ff; */
    border-radius: 30px;
    padding: 1.2rem;
    justify-content: space-between;
    color: rgba(82, 68, 125, 1);
    font: Varela Round;
  }
}


.digitalhealthcontainer {
  background-color: #fbf8ff;

  top: 282px;
  margin: 1rem;
  justify-content: flex-start;
  border-radius: 15px;
  color: #52447d;
  font-weight: "bold";
}
.digitalhealthlinks {
  /* margin-bottom: 62px; */
  margin-left: 2rem;
  padding: 2rem;
  font-size: 14px;
}

.digitalhealthfirstlink{
  margin-top: 20px;
  padding-left: 2rem;
  margin-left: 1.8rem;
  font-size:'15px'
}

@media (max-width: 822px) {
  .digitalhealthlinks {
    /* margin-bottom: 62px; */
    margin-left: 2rem;
    padding: 2rem;
    font-size: 12px;
  }

  .digitalhealthfirstlink{
    margin-top: 20px;
    padding-left: 1rem;
    margin-left: 1.9rem;
    font-size:'15px'
  }

  .digitalhealthlinks {
    /* margin-bottom: 62px; */
    margin-left: 1rem;
    padding: 2rem;
    font-size: 14px;
  }

}



.selectArticleSection {
  background-color: rgba(220, 220, 220, 0.3);
  border-left: 5px solid rgba(82, 68, 125, 1);
}

.hypertensionbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: baseline;
}

.image-link-container {
  width: 10%;
  height: 10%;
  margin: 10px;
  box-sizing: border-box;
}

.link-image {
  width: 40%;
  height: 40%;
  border: 1px solid #c01717;
  border-width: 10px;
}

.Articles-MainContainer {
  display: flex;
}

.AritclesBackground {
  /* background-color: rgba(234, 236, 255, 1); */
  /* padding-top: 1%; */
}

.ArticlesHeading {
  display: flex;
  width: 100%;
  /* padding: 1%; */
  background-color: rgba(247, 240, 255, 1);
  /* padding-left: 2%; */
  align-items: center;
}

.ArticleSection {
  height: 78vh;
  /* overflow: scroll; */
  background-color: white;
}

.sectionsNameArticle {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 1rem;
  /* border: 1px solid black; */
  /* border-radius: 12px; */
  cursor: pointer;
  border-bottom: 1px solid rgba(214, 229, 255, 1);
}

.sidemenuArticles {
  background: #ffffff;
  display: flex;
  height: 80vh;
  flex: 0.9;
}

.Articles-digitalHealth {
  height: 82vh;
  overflow: scroll;
  flex: 4.1;
}



.ArticlesIteams {
  font-size: 1rem;
  font-weight: 500 !important;
  line-height: 21px !important;
  color: rgba(82, 68, 125, 1);
  letter-spacing: 0rem !important;
}

.ArticlesHeading{
  border-radius: 10px 10px 0px 0px;
}

.ArticlesHeadingContainer{
  position: fixed;
  width: 83%;
}
