.HospitalsListMis {
  /* width: 50%; */
  margin: 2%;
  height: 65vh;
  border: 1px solid #d6e5ff;
  border-radius: 20px;
}

.HospitalsListMisH {
  height: 80vh;
  margin-top: 4%;
  width: 100%;
  /* overflow: scroll; */
}

.Misheading {
  color: #52447d;
  background-color: #d6e5ff;
  border-radius: 20px 20px 0px 0px;
  height: 7vh;
  display: flex;
  align-items: center;
  padding-left: 2.5%;
  justify-content: space-between;
}

.patientcountmis {
  display: flex;
  width: 25%;
  height: 20vh;
  background-color: #f7f0ff;
  border-radius: 20px;
  flex-direction: column;
  /* padding-left: 3%; */
  align-items: center;
}

.patientvisitHeading {
  display: flex;
  width: 75%;
  height: 3vh;
  border-radius: 20px;
  background-color: #ffcd2e;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.misdiv {
  display: flex;
  flex-direction: row;
  gap: 2%;
  justify-content: flex-start;
  /* margin-top: 10%; */
  margin-left: 2%;
}

.misSearch {
  height: 8vh;
  display: flex;
  align-items: center;
  margin: 2% 2%;
  gap: 3%;
}

.addp-inputField_mis {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 28%;
  border: 10px;
  /* margin-top: 3%;
  margin-left: 2%; */
}

.misSelect {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  color: #52447d;
  padding: 10px;
  padding-right: 30px;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 140 140" width="14px" height="14px" xmlns="http://www.w3.org/2000/svg"><polyline points="20 40 70 90 120 40" style="fill:none;stroke:%23333;stroke-width:20;stroke-linecap:round;stroke-linejoin:round;" /></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  border-radius: 10px;
  border: 1px solid #ededed;
  max-width: 400px;
  box-sizing: border-box;
  overflow: visible;
}

.misDate {
  display: flex;
  flex-direction: row;
  gap: 1%;
  justify-content: space-around;
}

.misSearchDate {
  background-color: #52447d;
  color: white;
  border-radius: 8px;
  padding: 1%;
  border: none;
  width: 6vw;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.misSearchDateclose {
  /* margin-left: 92%; */
  background-color: #52447d;
  color: white;
  border-radius: 8px;
  padding: 1%;
  border: none;
  width: 6vw;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20%;
}

.countstyle {
  font-size: 4rem;
  /* padding-left: 20%; */
  color: #52447d;
  margin-top: 15%;
}

.scrollable-element {
  overflow: auto;
}

.scrollable-element::-webkit-scrollbar {
  display: none;
}

.mispopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 100%;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  background-color: #2a2525b8;
  z-index: 100;
  gap: 1rem;
  overflow: scroll;
}

.mispopup-inner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: white; */
  /* padding: 3%; */
  /* width: 31%; */
  height: 90%;
  /* border-radius: 15px; */
  /* border: 1px solid #ccc; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.misdownloadButtonmain {
  display: flex;
  justify-content: center;
  margin: 4% 0%;
  padding: 2%;
}

.page-contentmis {
  display: flex;
  justify-content: center;
}