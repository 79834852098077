.bgAppointmentsPatientsBodyContainerAPM .calendarSection {
  border: 1px solid rgba(172, 197, 234, 1);
  border-radius: 14px;
  max-height: 515px;
  /* min-width: 380px; */
  background-color: #fff;
  flex: 2;
  width: 41%;
  height: 464px;
}

.calendarSection .CalenderHeading {
  display: flex;
  justify-content: space-between;
  background-color: #a1b5ff;
  color: white;
  padding: 9.5px 13px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.CalenderHeading h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.12em;
  text-align: left;
}

.CalenderHeading .innerCalenderheading {
  font-family: Outfit;
  font-size: 24px;
  font-weight: 600;
  line-height: 30.24px;
  letter-spacing: 0.12em;
  text-align: left;
  margin-left: 16px;
  color: #ffffff;
}

.CalenderAddButtonContainer {
  display: flex;
  justify-content: flex-end;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  /* margin: auto 15px 20px auto; */
}

.CalenderAddButtonContainer .CalenderLink1 {
  color: white;
}

.CalenderLink1 .addCalenderButton1 {
  width: 180px;
  height: 35px;
  /* font-weight: bold; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Centers text horizontally */
  border-radius: 8px;
  background-color: #52447d;
  font-size: 13px;
  letter-spacing: 0.05em;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  /* Ensures text alignment */
  line-height: normal;
  /* Reset line-height to avoid issues */
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Smooth transition */
}

.CalenderLink1 .addCalenderButton1:hover {
  background-color: #52447d;
  /* Slightly lighter shade on hover */
  transform: scale(1.05);
  /* Adds a slight zoom effect */
}



@media screen and (min-width: 1024px) {
  .calendarSection {
    width: 34%;
  }
}

@media screen and (min-width: 768px) {
  .calendarSection {
    width: 28%;
  }
}

.calendarSection .css-1vskg8q {
  display: none !important;
}

.calendarSection .css-5oi4td {
  /* height: 255px !important; */
}

.css-1ho05b .MuiPickersLayout-toolbar {
  display: none;
}