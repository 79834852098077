.examination-main {
  display: flex;
  /* padding: 3% 10%; */
  gap: 5%;
  /* height: 86vh; */
}

.treatmentplanmain {
  width: 90vw;
  overflow: scroll;
  /* background-color: #F7F3FE; */
}

.treatmentplan {
  border-radius: 20px 20px 0px 0px;
  /* background-color: #f7f3fe; */
  /* height: 83vh; */
}

.images {
  margin-left: 130%;
  margin-top: 72%;
  margin-right: 20%;
}

.theader {
  display: flex;
  font-family: Varela Round;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: white;
  height: 40px;
  gap: 1rem;
  align-items: center;
  background-color: rgba(227, 237, 255, 1);
  padding-left: 1%;
}

.treatment-heading {
  margin-top: 1%;
  margin-bottom: 1%;
  /* padding-top: 2%; */
  font-size: 21px;
  margin-left: 1%;
  color: rgba(9, 74, 115, 1);
}

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  background-color: #f7f3fe;
  /* border-radius: 23px; */
  /* width: 85%; */
  align-content: center;
}

/*Hypertension styling*/
.Custom-plan {
  gap: 3%;
  display: flex;
  /* padding: 0% 4%; */
}

.Custom-plan p {
  color: #5d4a87;
  font-size: 20px;
  font-weight: 400;
}

.Custom-plan label {
  font-size: 20px;
  color: #888888;
}

.Custom-plan input {
  width: 20px;
  height: 20px;
}

.Hypertension-form {
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  width: 80%;
  gap: 15px;
}

.personalInfoContainer {
  /* margin-bottom: 10%; */
}

.nameandclass {
  display: flex;
  gap: 5rem;
}

.Hypertension-form input[type="text"] {
  padding: 10px;
  background-color: #eae8ff;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* flex: 1; */
  /* padding-left: 5%; */
  border: 1px solid rgba(223, 223, 223, 1);
  /* font-size: 120%; */
  /* margin-right: 10%; */
  height: 52px;
  width: 48%;
  /* margin: 2%; */
}

input::placeholder {
  font-size: 20px;
  color: rgba(82, 68, 125, 1);
}

.checkdrugname {
  width: 20vw;
}

.days {
  width: 42vw;
  height: 10vh;
}

.Hypertension-form input[type="text"][name="drugClass"],
.Hypertension-form input[type="text"][name="drugName"],
.Hypertension-form input[type="text"][name="strength"],
.Hypertension-form input[type="text"][name="frequency"],
.Hypertension-form input[type="text"][name="instructions"],
.Hypertension-form input[type="text"][name="duration"],
.Hypertension-form input[type="text"][name="reason"],
.Hypertension-form input[type="text"][name="remarks"] {
  /* margin-right: 10px; */
  background-color: #eae8ff;
  padding-left: 5%;
  border: 1px solid rgba(223, 223, 223, 1);
  font-size: 120%;
  /* margin-right: 10%; */
  height: 6vh;
  width: 48%;
}

.TreatmentplanInputBox {
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.Hypertension-form input[type="text"][name="remarks"] {
  margin-right: 60%;
}

.Hypertension-section {

  padding-left: 2%;

}

.hypertension-submit {
  gap: 6%;
}

.Prescription {
  /* margin-left: 5%;  */
  width: 20%;
  height: 4vh;
  font-size: 1rem;
  color: #ffffff;
  background-color: #f7ab46;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  margin-top: 2%;
}

.pres {
  margin-left: 25%;
}

.add {
  align-items: center;
  width: 15%;
  height: 5vh;
  font-size: 1rem;
  /* padding-left: 10%; */
  /* padding-right: 10%; */
  color: #ffffff;
  background-color: #52447d;
  border-radius: 7px;
  border: none;
  cursor: pointer;
  /* margin-left: 30%; */
  margin-bottom: 3%;
}

.topic-container {
  background-color: #f0f0f0;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  overflow: hidden;
  transition: height 0.5s cubic-bezier(0, 1, 0, 1);
}

.topic-container h2 {
  color: #333;
  margin-bottom: 10px;
}

.topic-container p {
  color: #666;
}

.topic-container.show {
  max-height: 100vh;
  background-color: white;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
  padding-left: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  margin-left: -53%;
  width: 750%;
}

.menubuttons {
  color: #333;
}

.dropdownbody {
  background-color: #eae8ff;
  margin-left: 3%;
}

.class {
  color: #333;
  background-color: #8873c9;
  padding: 1%;
  margin-bottom: 1%;
}

.dropdownheader {
  display: flex;
  justify-content: space-between;
  background-color: #ebe3ff;
  padding: 2%;
  margin-bottom: 2%;
  border-radius: 15px;
}

.drugname {
  padding: 2%;
}

.drugclass {
  padding: 1%;
}

.bootomcard {
  justify-content: space-between;
  padding: 1%;
  background-color: #8873c9;
  margin-top: 3%;
  margin-bottom: 2%;
  display: flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.strengthbutons {
  color: #666;
  padding: 1%;
  margin: 5px;
}

.sbuttons {
  display: flex;
  cursor: pointer;
  width: 15%;
  justify-content: center;
  color: #333;
  background-color: gold;
  /* border: 10px; */
  padding: 1%;
  margin: 1%;
  border-radius: 20px;
  /* max-width: 20%; */
}

.frequncybutons {
  color: #666;
  padding: 1%;
}

.fbuttons {
  color: #333;
  background-color: gold;
  /* border: none; */
  padding: 1%;
  margin: 1%;
  border-radius: 20px;
  box-shadow: #333;
}

.instructionbutons {
  color: #666;
  padding: 1%;
}

.ibuttons {
  color: #333;
  background-color: gold;
  border: none;
  padding: 1%;
  margin: 1%;
  border-radius: 20px;
  /* max-width: 20%; */
}

.addvalues {
  display: flex;
  margin: 3%;
}

.addvalues>div {
  margin-right: 20px;
  /* Adjust as needed */
}

.decbutton {
  border: none;
  color: #eae8ff;
  background-color: gold;
  border-radius: 20px;
  margin-left: 5%;
}

.forbutton {
  border: none;
  background-color: gold;
  color: #eae8ff;
  width: 5vw;
  border-radius: 20px;
}

.count {
  color: #333;
}

.weekbutton {
  border: none;
  background-color: gold;
  color: #eae8ff;
  width: 5vw;
  border-radius: 20px;
}

.addbutton {
  background-color: #52447d;
  color: #eae8ff;
  padding: 0.5;
  border-radius: 20px;
  width: 8vw;
  height: 4vh;
  border: none;
  margin: 1%;
}

.dropheader {
  display: flex;
  /* justify-content: space-between; */
  border: none;
  background-color: #ebe3ff;
}

.ABCbuttons {
  display: flex;
  margin: 2%;
}

.resultbuttons {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.abcbuutons {
  padding: 3%;
  width: 5vw;
  height: 5vh;
  border: none;
  border-radius: 10px;
}

.abcbuutons.active {
  color: #fff;
  background-color: #5d4a87;
}

.drugheadermessage {
  /* background-color: #ebe3ff; */
  margin-bottom: 2%;
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid rgba(247, 240, 255, 1);
}

.drugmessageheader {
  background-color: #8873c9;
  padding: 1%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.activeindex {
  display: flex;
  justify-content: space-between;
}

/* .drugnamesss{
  margin-right: 50%;
} */
.drugclasss {
  margin-right: auto;
  margin-left: 75%;
}

.sbuttons.selected {
  background-color: green;
  color: white;
}

.selected {
  background-color: #007bff;
  color: #fff;
}

.prescriptionbutton {
  padding-left: 2%;
}

.addbutton-disabled {
  background-color: grey;
  color: white;
}

.drugmes {
  color: rgba(82, 68, 125, 1);
  background-color: rgba(247, 240, 255, 1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1%;
  font-weight: 600;
}

.butttonsss {
  border-radius: 10px;
  background-color: gold;
  display: flex;
  /* width: vw; */
}

.frequncydropdown {
  border: none;
  /* background-color: #eae8ff; */
  height: 8vh;
  border-radius: 20px;
  width: 38%;
  margin-top: 5%;
  padding-left: 4%;
  border: 0px solid #ccc;
  font-size: 90%;
  margin-right: 14%;
  margin-left: 1%;
}

.instructiondropdown {
  border: none;
  /* background-color: #eae8ff; */
  height: 8vh;
  width: 20vw;
  border-radius: 20px;
  margin-top: 5%;
  padding-left: 4%;
  border: 0px solid #ccc;
  font-size: 90%;
  margin-right: 10%;
}

.frequncyinstructions {
  /* margin-left: 10%; */
  /* flex-wrap: wrap;
  margin-bottom: 5%; */
  display: flex;
  flex-direction: row;
  gap: 5%;
}

.Treatmentplandropdown {
  border: none;
  background-color: #eae8ff;
  height: 6vh;
  border-radius: 10px;
  width: 48%;
  /* margin-top: 5%; */
  padding-left: 4%;
  border: 1px solid rgba(223, 223, 223, 1);
  font-size: 90%;
  font-size: 16px;
  color: rgba(82, 68, 125, 1);
  /* margin-right: 14%; */
  /* margin-left: 1%; */
}

.toggle-buttonTab[disabled] {
  background-color: #dddddd;
  color: #888888;
  border-radius: 5px;
}

.strengthandfrequncy {
  display: flex;
  gap: 5rem;
}

.Instruction {
  display: flex;
  gap: 5rem;
}

.checkstrength {
  width: 20vw;
}

.instructionanddays {
  width: 20vw;
}

.checkduration {
  background-color: #eae8ff;
  padding-left: 4%;
  border: none;
  font-size: 120%;
  margin-right: 10%;
  size: 50%;
}

.duration-input::placeholder {
  /* Style for the placeholder text */
  padding: 50%;
  margin: 50%;
}

.totalform {
  margin-left: 15%;
}


.TreatmentplanInputBoxnumbers {
  background-color: #eae8ff;
  padding-left: 5%;
  border: 1px solid rgba(223, 223, 223, 1);
  font-size: 16px;

  /* margin-right: 10%; */
  height: 6vh;
  width: 48%;
  border-radius: 10px;
  padding: 10px;
  padding-left: 4%;

}


.drugdetailsdefault {
  background-color: white;

}

.minusButton {
  border: none;
  color: white;
  background-color: rgba(82, 68, 125, 1);
}

.plusButton {
  border: none;
  color: white;
  padding: 2%;
  background-color: rgba(82, 68, 125, 1);
}