.homePatient {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  min-height: 79vh;
  /* 86vh; */
  /* padding: 2%; */
}

.addp-homePatient {
  display: flex;
  flex-direction: column;
  /* background-color: #f6f6f6; */
  /* height: 100vh; */
  padding-top: 1%;
  /* 86vh; */
  /* padding: 2%; */
  width: 100%;
  overflow-y: scroll;
}

.addp-patientsInputField .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: transparent !important;
}

.homeBodyPatients {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  /* padding: 2% 6% 0 4%; */
  background: transparent;
  position: relative;
  z-index: 2;
  /* padding-bottom: 40px; */

  flex-direction: column;
  align-items: center;
}

.dateofbirth {
  width: 80%;
  background: #e3ebff;
  border: none;
  outline: none;
  height: 35px;
  padding: 5px 10px;
  border-radius: 6px;
}

.dateofbirth input {
  background: transparent !important;
  margin-bottom: -5px;
}

.homeBackground {
  position: absolute;
  object-fit: cover;
  bottom: 0;
  left: -65px;
}

.homeBackground img {
  height: 700px;
  width: 85vw;
}

.icon {
  width: 38px;
  height: 40.18px;
}

.homeBottomContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addp-patientsHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: linear-gradient(180deg, #6688ff 0%, #0c3ce7 100%); */
  color: #536df2;
  /* color: black; */
  padding: 12.5px 30px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  color: #52447d;
  background-color: #eaeeff;
}

.patientsHeading h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.12em;
  text-align: left;
}

.addp-addPatientsAddressButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.addPatientsButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  min-width: 230px;
  background: #fffafa;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.addp-addPatientsButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 88.5%;
  height: 44px;
  gap: 10px;
  margin-top: 50px;
  margin-bottom: 25px;
}

.addp1-addPatientsButton {
  width: 230px;
  height: 52px;
  border-radius: 5.5px;
  /* background: linear-gradient(
    180deg,
    rgb(153.79, 151.73, 255) 10.78%,
    rgb(95, 78, 134) 90.15%
  ); */
  background-color: #52447d;
  font-size: 14px;
  font-weight: 400;
  /* line-height: 23px; */
  letter-spacing: 0.05em;
  box-shadow: 0px 1px 4px 0px #00000040;
  color: white;
  border: none;
  cursor: pointer;
}

.addp2-addPatientsButton {
  width: 230px;
  height: 52px;
  border-radius: 5.5px;
  /* background: linear-gradient(
    180deg,
    rgb(153.79, 151.73, 255) 10.78%,
    rgb(95, 78, 134) 90.15%
  ); */
  background-color: #52447d;
  font-size: 14px;
  font-weight: 400;
  /* line-height: 23px; */
  letter-spacing: 0.05em;
  box-shadow: 0px 1px 4px 0px #00000040;
  color: white;
  border: none;
  cursor: pointer;
}

.addp-addPatientsButton {
  width: 150px;
  height: 40px;
  border-radius: 28.5px;
  /* background: linear-gradient(
    180deg,
    rgb(153.79, 151.73, 255) 10.78%,
    rgb(95, 78, 134) 90.15%
  ); */
  /* line-height: 23px; */
  letter-spacing: 0.05em;
  border: none;
  cursor: pointer;
  background-color: #eee1ff;
  color: #52447d;
  font-family: Outfit;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.9px;
  text-align: center;
}

.link {
  color: white;
}

.nurseImage {
  height: 400px;
}

.Main-patient {
  width: 99%;
}

.patientContainer {
  /* box-shadow: 0px 0px 250px -23px rgba(83.3, 0, 238, 0.25); */
  border-radius: 28.5px;
  z-index: 2;
  position: relative;
  width: 100%;
  /* box-shadow: 0px 4px 39px 11px #95ecff40; */
}

::-webkit-scrollbar {
  width: 5px;
}

.homeBodyLeft {
  position: relative;
  z-index: 2;
}

.patientInput {
  width: 100%;
  min-width: 230px;
  height: 30px;
  border: none;
  border-bottom: 1px solid #ececec;
  border-width: 3px;
  background-color: transparent;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
}

.css-7ftcsh {
  border: none !important;
}

.abhaInput {
  height: 35px;
  border: none;
  background-color: #d7f3ff;
  padding: 0 15px;
  border-radius: 6px;
  outline: none;

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
}

.description {
  font-size: 16px;
  font-weight: bolder !important;
  line-height: 20px;
  letter-spacing: 0.01em;
  font-weight: 400;
  color: rgba(82, 68, 125, 1);
}

.addp-formContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  line-height: 1px;
  background-color: #fff;
  padding: 4%;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  overflow: scroll;
}

.addp-formContainer1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  line-height: 1px;
  background-color: #fff;
  padding: 4%;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.addp-inputField {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 50%;
}

.addp-inputFieldAddress {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 75%;
}


.addp-patientsInputField {
  width: 78.5%;
  /* background: #e3ebff; */
  /* outline: none; */
  height: 45px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  color: #555;
  background-color: #fff !important;
}

.addp1-patientsInputField {
  width: 90%;
  /* background: #e3ebff; */
  border: none;
  /* outline: none; */
  height: 45px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  color: #555;
}

.addp-patientsInputField:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.patientDetailsContainer {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px; */
  display: flex;
  flex-direction: row;
  /* gap: 60px; */
  /* justify-content: space-between; */
}

.patientButtonContainer {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px; */
  display: flex;
  flex-direction: row;
  gap: 28px;
  justify-content: flex-end;
  padding-bottom: 2%;
}

.abhadetails {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  padding: 2% 6% 0 4%;
  background: transparent;
  position: relative;
  z-index: 2;
  /* padding-bottom: 40px; */
}

.addp-inputField .css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 78% !important;
  padding: 1%;
  background-color: white !important;
  border: 1px solid #ccc;
  border-radius: 10px;
  height: 46px;
}

.addp-inputField .css-1uvydh2 {
  padding: 12px 0px 12px 14px !important;
}

.addp-inputField .css-1bn53lx {
  border-radius: 12px;
}

/* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: #fff !important;
} */
/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 33%;
  width: 78%;
} */

.addp-addSaveButtonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 35px;
  width: 90%;
}

.MuiTextField-root {
  width: 78% !important;

}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  padding: 10px;
  color: #333;
  padding-right: 30px;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 140 140" width="14px" height="14px" xmlns="http://www.w3.org/2000/svg"><polyline points="20 40 70 90 120 40" style="fill:none;stroke:%23333;stroke-width:20;stroke-linecap:round;stroke-linejoin:round;" /></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  height: 40px;
}

.facilityCheckbox {
  padding: 0.7rem 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  background-color: #efeafc;
  border-radius: 1rem;
  align-items: center;
}