/* .BillingMaincontainer {

  border: 1px solid #FFFFFF;
  border-radius: 20px;
} */

.BillingHeading {
  background-color: rgba(227, 237, 255, 1);
  padding: 2%;
  color: rgba(9, 74, 115, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px 15px 0px 0px;
}

.BillingInvoice {
  /* margin: 5%; */
  border: 1px solid rgba(247, 240, 255, 1);
  border-radius: 10px;
  background-color: white;
}

.BillingIteams th {
  padding: 10px 20px;
}

.BillingIteams td {
  padding: 5px 10px;
}

.BillingInvoiceHeader {
  background-color: rgba(247, 240, 255, 1);
  padding: 2%;
  border-radius: 10px 10px 0px 0px;
}

.BillingInputfields {
  margin: 2%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-addPatientsButton {
  width: 150px;
  border-radius: 5.5px;
  background-color: #52447d;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.05em;
  box-shadow: 0px 1px 4px 0px #00000040;
  color: white;
  border: none;
  cursor: pointer;
}

.BillingItems {
  display: flex;
  justify-content: center;
}

.BillingItems thead {
  background-color: rgba(82, 68, 125, 1) !important;
  color: white;

}

.BillingItems tbody {
  background-color: rgba(247, 240, 255, 1);
}

.BillingItems tbody {
  padding: 1%;
}

.backbutton {
  margin: 2% 2% 0;
  padding: 7px 20px;
  text-align: center;
  color: white;
  background: #52447d;
  border-radius: 5px;
  border: none;
}