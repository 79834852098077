.header {
  display: flex;
  font-family: Varela Round;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: white;
  height: 40px;
  gap: 1rem;
  align-items: center;
  background-color: rgba(227, 237, 255, 1);
}

.diagnosis {
  width: 80vw;
  /* height: 45px; */
  gap: 0px;
  border-radius: 20px 20px 0px 0px;

  /* background-color: #5D4A87; */
}

.accordion-content {
  padding-left: 5%;
  padding-top: 2%;
  width: 88%;
  background-color: #ffffff;
  margin-left: 2.5%;
  border-radius: 0px 0px 15px 15px;
}

.image {
  /* gap: "3rem";
  height: "10px";
  align-items: "center";
  cursor: "pointer";
  margin-top: 23%;
  padding-left: 55%;
  width: 1.5rem;
  height: 1rem;
  margin-top: 41%; */
}

.diagnosis-main {
  background-color: #f6f6f6;
  overflow: scroll;
}

.accordion {
  /* background-color: white; */
  overflow: scroll;
}

.accordion-header {
  cursor: pointer;
  font-size: 15px;
  font-weight: 300;
  color:
    #52447D;
  /* border: 1px solid #fcb24857; */
  background-color: #d6e5ff;
  width: 88.7%;
  gap: 0px;
  border-radius: 9px;
  text-align: left;
  padding: 1%;
  margin-left: 2%;
  display: flex;
}

.confirm-daignosis {
  display: flex;
  width: 90%;
  gap: 0px;
  border-radius: 9px 0px 0px 0px;

  color: white;
  font-size: 15px;
  background-color: #FBAF42;
  border: none;
  border-radius: 10px;
  text-align: left;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 2%;
  cursor: pointer;
}

.forward {
  padding-left: 87%;
  padding-top: 5%;
}

#note {
  background-color: #fffee3;
  margin-right: 13%;
  margin-bottom: 2%;
  padding-left: 2%;
  width: 55%;
  box-shadow: 0px 1px 0px 0px black;
  font-family: Varela Round;
  display: flex;
}

.agree {
  background-color: #1cca00;
  color: #ffffff;
  border: none;
  border-radius: 14.5px;
  width: 142px;
  height: 29px;
  margin-left: 5%;
}

.myInput {
  width: 326px;
  height: 29px;
  background-color: #fffee3;
  border: none;
  padding-right: 2%;
  box-shadow: #000000;
}

.disagree {
  background-color: #1cca00;
  color: #ffffff;
  border: none;
  border-radius: 14.5px;
  width: 142px;
  height: 29px;
  margin-left: 5%;
}

.MuiTextField-root {
  width: 551px;
}

.diagnosis h1 {
  font-size: 1.1rem;
  /* padding-top: 1%; */
  color: #5d4a87;
}

.diagnosis-confirm {
  /* height: 25%; */
  padding-top: 3%;
  margin-left: 2%;
  margin-bottom: 2%;
}

.buttondia {
  color:
    #52447D;
  background-color: #ffdc72;
  border: none;
  border-radius: 14.5px;
  width: 142px;
  height: 29px;
  margin-left: 5%;
}

.imager {
  height: 2%;
  width: 2%;
  margin-left: auto;
  margin-right: 2%;
  /* padding-top: 0.5 %; */
  /* margin-top: 1.3%; */
  cursor: pointer;
  display: flex;
  align-items: center;
}

.cbutton {
  display: flex;
  font-size: 18px;
  align-items: center;
  width: 100%;
}

.expand-icon {
  margin-left: auto;
  margin-right: 3%;
}

.score {
  width: 88.2%;
  margin-left: 2%;
  color: #f6f6f6;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 3%;
  background-color: #81ee70;
  border-radius: 10px 10px 10px 10px;
}

.inputfield {
  /* border: none; */
  /* border-bottom: 1px black; */
  border-top: 1px black;
  border-left: 1px black;
  border-right: 1px black;
  width: 45vw;
  margin-top: 5%;
  height: 8vh;
  margin-bottom: 5%;
}

.risk {
  display: flex;
  color: #ffffff;
  margin-right: 5%;
  margin-left: 2%;
  /* height: 10vh; */
}

.cvd {
  /* margin-right: 65%; */
  margin-right: 60%;


}

#conditions {
  width: 88.2%;
  margin-top: 3%;
  color: #ffffff;
}


.arrowSize {
  width: 0.7rem;
}

.arrowSizeUD {
  width: 1.1rem;
}