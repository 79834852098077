/* .profileLeftContainer {
  width: 295px;
  box-shadow: 0px 0px 8px 0px #c2c2c2;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  height: 86vh;
}


.profileRightContainer {
  box-shadow: 0px 0px 8px 0px #c2c2c2;
  width: 100%;
  padding: 2rem;
  height: 86vh;
  overflow: scroll;
}

.sectionsName {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0.5rem 3rem;
  cursor: pointer;
}

.sectionsName img {
  height: 21px;
  width: 21px;
}

.sectionsName p {
  font-family: Vazirmatn;
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.085em;
  text-align: left;
  white-space: nowrap;
}

.profileSection {
  display: flex;
}

.upload_image {
  border: 1px solid #9d9d9d;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  font-family: Vazirmatn;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.15em;
  cursor: pointer;
}

.upload_image1 {
  border-radius: 7px;
  border: 1px solid rgba(82, 68, 125, 1);
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: #52447d;
  font-family: Vazirmatn;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.09em;
  text-align: center;
}

.headerEdu {
  padding: 0.5rem 1rem;
  border-radius: 7px;
  font-family: Vazirmatn;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15em;
}

.educationDetails {
  color: #52447d;
  font-family: Vazirmatn;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1125rem;
  border-radius: 12px;
  border: 1px solid #52447d;
  width: 25vw;
  padding: 0.5rem 1rem;
}

.topContainer {
  display: flex;
  gap: 4rem;
  align-items: center;
}

.educationCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 9vh;
  border-radius: 12px;
  border: 1px solid #acc5ea;
  background-color: #e0edff;
}

.experinceCard {
  display: flex;
  align-items: start;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #8873c9;
  flex-direction: row;
}

.row1 {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 20px;
  flex-direction: row;
}

.row2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  padding-left: 30px;
  justify-content: space-between;
}

.educationDetailsCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 219px;
  border-radius: 12px;
  border: 1px solid #8873c9;
  padding: 20px;
  text-align: left;
  width: 241px;
}

.deleteicon {
  padding: 5px 20px;
  cursor: pointer;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.degreeName {
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  white-space: collapse balance;
  font-size: 14px;
  color: #52447d;
}

.details {
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  white-space: collapse balance;
  font-size: 16px;
  color: #8d8bff;
}

.editButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #52447d;
  border-radius: 7px;
  padding: 0.3rem 1rem;
  gap: 1rem;
  cursor: pointer;
}

.editButton2 {
  background-color: #52447d;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #52447d;
  border-radius: 7px;
  padding: 0.3rem 1rem;
  gap: 1rem;
  cursor: pointer;
}

.version {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #52447d;
  border-radius: 31px;
  padding: 0.3rem 1rem;
  gap: 1rem;
  cursor: pointer;

  font-family: Vazirmatn;
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.085em;
  text-align: left;
}

.years {
  background-color: #f4c23f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0.3rem 1rem;
  gap: 1rem;

  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1em;
  font-size: 14px;
  color: #ffffff;
}

.addButton {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #52447d;
  border-radius: 5px;
  padding: 0.3rem 1rem;
  gap: 1rem;
  cursor: pointer;
}

.headingContainer {
  display: flex;
  justify-content: space-between;
}

.bottomContainer {
  border: 1px solid #b6b6b6;
  border-radius: 16px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.personalInfoLabel {
  font-family: Vazirmatn;
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 3px;
  line-height: 18px;
  letter-spacing: 0.085em;
  color: #767676;
  white-space: pre-wrap;
}

.personalInfoValue {
  font-family: Vazirmatn;
  font-size: 17px;
  font-weight: 550;
  line-height: 20px;
  letter-spacing: 8.5%;
}

.personalInfoHeading {
  font-family: VarelaRound;
  font-size: 17px;
  color: #52447d;
  font-weight: 600;
  line-height: 24.15px;
  letter-spacing: 0.085em;
  text-align: left;
}

.InfoHeading {
  font-family: Vazirmatn;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.085em;
  text-align: left;
}

.personalInfoInput {
  background: #eeecf5;
  border: none;
  outline: none;
  padding: 0 0 0 15px;
}

.value {
  font-family: Vazirmatn;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.21px;
  letter-spacing: 7.5%;
  color: #747474;
}

.personalInfoInput {
  font-family: Vazirmatn;
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.085em;
  text-align: left;
  color: black;
  height: 17px;
}

.experinceInput {
  color: #52447d;
  font-family: Vazirmatn;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1125rem;
  border-radius: 12px;
  background-color: #eeecf5;
  border: none;
  width: 18vw;
  padding: 0.5rem 1rem;
}

.abdmHeader {
  border: 0.5px;
  font-family: Varela Round;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.11em;
  text-align: center;
  color: rgba(82, 68, 125, 1);
}

.radio {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 3rem;
  cursor: pointer;
}

.radioLabel {
  font-family: Varela Round;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(82, 68, 125, 1);
}

.AcceptableDocHeader {
  font-family: "Varela Round";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(82, 68, 125, 1);
}

.About {
  font-family: "Varela Round";
  font-size: 21px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.085em;
  text-align: left;
}

.AboutText {
  font-family: "Varela Round";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.085em;
  text-align: left;
} */

.profile-page-container {
  width: 100%;
  /* margin-top: 1%; */
  overflow: hidden;
  height: 100%;
}

.rege-bottomContainer {
  width: 100%;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  gap: 13px;
}

.regm-bottomContainer {
  width: 100%;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
}

.profileLeftContainer {
  /* width: 20%; */
  /* box-shadow: 0px 0px 8px 0px #c2c2c2; */
  background: #ffffff;
  display: flex;
  flex-direction: column;
  /* padding-top: 2rem; */
  /* height: 86vh; */
  border-right: 1px solid #00000021;
}

.profileRightContainer {
  /* box-shadow: 0px 0px 8px 0px #c2c2c2; */
  width: 97.7%;
  /* padding-left: 3rem; */
  /* height: 87vh; */
  overflow: auto;
  display: flex;
  /* gap: 1rem; */
  flex: 3;
  margin-left: 15px;
  /* margin-top: 7rem; */
}

.helpProfileRightContainer {
  /* box-shadow: 0px 0px 8px 0px #c2c2c2; */
  width: 97.7%;
  /* padding-left: 3rem; */
  height: 87vh;
  overflow: scroll;
  display: flex;
  /* gap: 1rem; */
  flex: 3;
  margin-left: 15px;
}

.aboutProfileRightContainer {
  /* box-shadow: 0px 0px 8px 0px #c2c2c2; */
  width: 97.7%;
  /* padding-left: 3rem; */
  height: 87vh;
  /* overflow: scroll; */
  display: flex;
  /* gap: 1rem; */
  flex: 3;
  /* margin-left: 15px; */
}

.helpProfileRightContainer {
  /* box-shadow: 0px 0px 8px 0px #c2c2c2; */
  width: 97.7%;
  /* padding-left: 3rem; */
  height: 80vh;
  /* overflow: scroll; */
  display: flex;
  /* gap: 1rem; */
  flex: 3;
  margin-left: 15px;
}

.bg-profileContainer {
  display: flex;
  flex-direction: column;
  /* gap:2rem; */
  overflow: auto;
  /* height:77vh; */
  width: 221px;
}

@media screen and (max-width: 1440px) {
  .bg-profileContainer {
    /* width: 236px; */
  }
}

@media screen and (max-width: 1024px) {
  .bg-profileContainer {
    width: 165px;
  }
}

@media screen and (max-width: 768px) {
  .bg-profileContainer {
    width: 121px;
  }
}

.navbar-container {
  width: 100%;
  height: 8.5vh;
  /* top: 142px;
  left: 117px; */
  gap: 0px;
  /* border-radius: 0px 0px 10px 10px; */

  background-color: #f7f0ff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.profile-heading {
  font-family: Outfit;
  font-size: 22px;
  font-weight: 500;
  line-height: 27.72px;
  letter-spacing: 0.12em;
  text-align: left;
  color: #094a73;
  padding-left: 20px;
}

.sectionsName {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 0.5rem 0.5rem;
  border-bottom: 1px solid #d6e5ff;
  /* border: 1px solid black; */
  /* border-radius: 12px; */
  cursor: pointer;
  height: 70px;
  width: 220px;
}

.sectionsName img {
  height: 21px;
  width: 21px;
}

.sectionsName p {
  font-family: Vazirmatn;
  font-size: 1rem;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.085em;
  text-align: left;
  white-space: nowrap;
}

.profileSection {
  display: flex;
  height: 77.2vh !important;
  /* overflow-y: scroll; */
  /* min-height: 100vh; */
}

/* UpdatePersonalInfo.jsx */
.Image {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.pi-Image {
  display: grid;
  align-items: center;
}

.pi-image-label,
.pi-image-src {
  grid-area: 1/1;
}

.pi-image-label {
  height: 135px;
  width: 195px;
  display: flex;
  align-items: flex-start;
  justify-content: right;
}

.pi-image-label>img {
  height: 25px;
  width: 25px;
  border-radius: 50px;
  border: 1px solid #dfdfdf;
}

.pi-image-label>img:hover {
  border: 1px solid #000;
}

.upload_image {
  border: 1px solid #9d9d9d;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  font-family: Vazirmatn;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.15em;
  /* width: fit-content; */
  /* text-align: left; */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.upload_image1 {
  border-radius: 7px;
  border: 1px solid rgba(82, 68, 125, 1);
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: #52447d;
  font-family: Vazirmatn;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.09em;
  text-align: center;
}

.Line {
  background: #aeaeae;
  width: 100%;
  height: 1px;
}

.personalInfoContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.Spacebtwfields {
  display: flex;
  flex-direction: column;
  width: 30.33%;
}

.Drfield {
  display: flex;
  flex-direction: column;
  width: 10.33%;
}

.DrDropdown {
  width: 100%;
  height: 29px;
  border: none;
  background: #e3ebff;
  margin-top: 16px;
}

.Addfield {
  flex-direction: column;
  padding-top: 20px;
}

/* UPDATE EXP CSS */
.experinceCard {
  display: flex;
  align-items: start;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid #8873c9;
  flex-direction: row;
  width: 18vw;
  height: 30vh;
  align-items: start;
  align-content: start;
  flex-direction: column;
}

.row1 {
  display: flex;
  align-items: center;
  gap: 10px;
  /* padding-left: 20px; */
  flex-direction: column;
  margin-bottom: 20px;
  /* text-align: center; */
}

.row2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
  padding-left: 30px;
  justify-content: space-between;
  font-size: 22px;
}

.educationDetailsCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 219px;
  border-radius: 12px;
  border: 1px solid #8873c9;
  padding: 20px;
  text-align: left;
  width: 241px;
}

.deleteicon {
  padding: 5px 20px;
  cursor: pointer;
  text-align: center;
}

.innerdeleteicon {
  /* background-color: #d0e2df; */
  /* border-radius: 20px;
  padding: 5px 20px; */
  /* cursor: pointer; */
  text-align: right;
  /* width: 10px; */
  height: 20px;
  margin-top: 8px;
  padding-right: 15px;
}

.innerdeleteicon-img {
  /* margin-top: 15px;
  margin-right: 20px; */
  cursor: pointer;
}

.bg-deleteicon {
  text-align: right;
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: -17px;
}

.exp-logo-img {
  height: 90px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.Nameandimg {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.Addexpbtn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
  align-items: center;
}

.degreeName {
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  white-space: collapse balance;
  font-size: 14px;
  color: #52447d;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.2rem;
}

.Boxwidth {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.Profdet {
  color: #7e7e7e;
  font-family: Varela Round;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.125rem;
}

.fieldsize {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.yrmnthfields {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  width: 50%;
}

.yrmnthfieldsrow {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px;
  gap: 5%;
}

.details {
  /* line-height: 16px; */
  letter-spacing: 0.1em;
  white-space: collapse balance;
  font-size: 16px;
  color: #0f0f0f;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.42px;
  text-align: left;
  /* width:160px; */
}

/* .detailsContainer {
  width: 100%; 
  overflow-wrap: break-word; 
  word-wrap: break-word;
  overflow: visible;
} */

.editButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #52447d;
  border-radius: 7px;
  padding: 0.3rem 1rem;
  gap: 1rem;
  cursor: pointer;
}

.editButton2 {
  background-color: #52447d;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #52447d;
  border-radius: 7px;
  padding: 0.3rem 1rem;
  gap: 1rem;
  cursor: pointer;
  width: fit-content;
  /* margin-left: auto; */
  /* margin-right: 15px; */
}

.version {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #52447d;
  border-radius: 31px;
  padding: 0.3rem 1rem;
  gap: 1rem;
  cursor: pointer;

  width: 30%;
  height: 45px;
  background-color: white;

  font-family: Vazirmatn;
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.085em;
  text-align: left;
}

.years {
  background-color: #fffad0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #52447d; */
  border-radius: 20px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1em;
  font-size: 0.9rem;
  color: black;
  width: 76%;
  min-height: 40px;
}

.years span {
  font-size: 1.1rem;
}

.hospital-row {
  width: 67px;
  height: 21px;
  font-family: Outfit;
  font-size: 17px;
  font-weight: 400;
  line-height: 21.42px;
  /* text-align: center; */
  color: #c0bcbc;
}

.addButton {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #52447d;
  border-radius: 5px;
  padding: 0.3rem 1rem;
  gap: 1rem;
  cursor: pointer;
}

.rege-headingContainer {
  width: 100%;
}

.headingContainer {
  display: flex;
  justify-content: space-between;
}

.bottomContainer {
  /* border: 1px solid #b6b6b6; */
  border-radius: 16px;
  /* padding: 2rem 4.5rem; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* padding: 1% 0rem; */
  margin-right: 10px;
}

.leftContainer {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  /* gap: 2rem; */
  height: 67.5vh;
}

.hostpitalLeftContainer {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  height: 71.5vh;
}

.personalInfoLabel {
  font-family: Vazirmatn;
  font-size: 15px;
  font-weight: 400;
  padding-bottom: 3px;
  line-height: 18px;
  letter-spacing: 0.085em;
  color: #767676;
  white-space: pre-wrap;
}

.hsptlsetup {
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  border: 1px solid #acc5ea;
  color: #52447d;
  font-family: Varela Round;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14375rem;
  padding: 1%;
  padding-left: 3%;
  text-align: left;
  background-color: #d6e5ff;
}

.hsptlsetup>.plus-icon {
  font-size: 1.5rem;
}

.hsptlsetup>.close {
  font-size: 2rem;
  cursor: pointer;
}

.hsptlsetup>.header-text {
  display: inline-block;
  width: 94%;
  padding-left: 1%;
}

.personalInfoValue {
  font-family: Vazirmatn;
  font-size: 17px;
  font-weight: 550;
  line-height: 20px;
  letter-spacing: 8.5%;
  /* color: #000000; */
  width: 163px;
  /* padding: 10px; */
  height: 29px;
}


.personalInfoHeading {
  font-family: VarelaRound;
  font-size: 17px;
  color: #52447d;
  font-weight: 600;
  line-height: 24.15px;
  letter-spacing: 0.085em;
  text-align: left;
}

.InfoHeading {
  font-family: Vazirmatn;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.085em;
  text-align: left;
}

.personalInfoInput {
  /* border-radius: 4px; */
  /* border: 1px solid rgba(82, 68, 125, 1); */
  background: #eeecf5;
  border: none;
  outline: none;
  padding: 0 0 0 15px;
}

.value {
  font-family: Vazirmatn;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.21px;
  letter-spacing: 7.5%;
  color: #747474;
}

.personalInfoInput {
  font-family: Vazirmatn;
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.085em;
  text-align: left;
  color: black;
  height: 17px;
}

.experinceInput {
  /* color: #C1C1C1;
  font-family: Vazirmatn;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1125rem;
  border-radius: 12px; */
  /* border: 1px solid rgba(82, 68, 125, 1);
  background-color: #eeecf5;
  border: none;
  width: 18vw;
  padding: 0.5rem 1rem; */
  border-color: rgba(82, 68, 125, 1);
  border-radius: 10px;
  border-width: 2px;
  width: 185px;
  height: 48px;
  box-shadow: none;
  background-color: #ffffff;
  border: 1px solid #aaa;
}

.experinceInput :hover {
  border-color: "black";
  color: "rgba(82, 68, 125, 1)";
}

.experinceInput::placeholder,
.css-qbdosj-Input::placeholder {
  font-size: 100%;
  /* Adjust the percentage to decrease the size */
}

.abdmHeader {
  border: 0.5px;
  font-family: Varela Round;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.11em;
  text-align: center;
  color: rgba(82, 68, 125, 1);
}

.radio {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 3rem;
  cursor: pointer;
}

.radioLabel {
  font-family: Varela Round;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(82, 68, 125, 1);
}

.AcceptableDocHeader {
  font-family: "Varela Round";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: rgba(82, 68, 125, 1);
}

.About {
  font-family: "Varela Round";
  font-size: 21px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.085em;
  text-align: left;
}

.all-main-sections {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  /* overflow: scroll; */
  background-color: white;
}


.p_text {
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  justify-content: center;
  text-align: left;
}

.p_textinner {
  display: flex;
  gap: 2rem;
  cursor: pointer;
  justify-content: flex-start;
}

.p_HprReg {
  font-weight: 600;
  font-size: 19px;
}

.p_Benfits {
  padding: 1rem;
  font-weight: 600;
  font-size: 19px;
  text-decoration: underline;
  color: gray;
}

.ul_lines {
  list-style-type: disc;
  padding-left: 1.5rem;
  color: gray
}

.Hfr_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.clickHereBtn {
  width: 97px;
  height: 32px;
  border-radius: 12px;
  border: 1px solid #8873C9;
  background: #8873C9;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
}

.skip_div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0rem;
  justify-content: flex-start;
  align-items: center;
}

.skip_p {
  font-family: "Varela Round";
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #52447D;
  text-decoration: underline;
  cursor: pointer;
}

.Benfits_abdm {
  padding: 1rem;
  font-weight: 600;
  font-size: 19px;
  text-decoration: underline;
  color: gray;
}

.ul_online {
  list-style: disc;
  padding-left: 1.5rem;
  color: gray;
}

.a_clickhere {
  width: 97px;
  height: 32px;
  border-radius: 12px;
  border: 1px solid #8873C9;
  background: #8873C9;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 1%;
}

.bridge_ul {
  display: flex;
  align-items: center;
  height: 9vh;
  border: 2px solid rgba(136, 115, 201, 1);
  border-radius: 12px;
  background: white;
  padding: 0px 1rem;
}

.p_stepts {
  padding: 1rem;
  font-weight: 500;
  font-size: 19px;
  text-decoration: underline;
  color: gray;
}

.hprRegistration {
  font-weight: 600;
  font-size: 19px;
}

.benofHeal {
  padding: 1rem;
  font-weight: 600;
  font-size: 19px;
  text-decoration: underline;
  color: gray;
}

.ul_Disc {
  list-style: disc;
  padding-left: 1.5rem;
  color: gray;
}

.clickmeButtton {
  width: 97px;
  height: 32px;
  border-radius: 12px;
  border: 1px solid #8873C9;
  background: #8873C9;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.div_clickme {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 1%;
  gap: 1%;
}

.p_text_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.div_p_text {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.AboutText {
  font-family: "Varela Round";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.085em;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 2%;
  gap: 1.5rem;
  border: 1px solid #ffc15b;
  border-radius: 6px;
  /* max-height: 350px;  */
  overflow-y: auto;
}

/* helppage css */
/* .Vidtitle {
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 26px;
  color: #000000;
  padding: 1px 4px;
  font-family: "Inter";
} */

/* .videoPlayerContainer {
  padding-top: 80px;
} */

/* ABDM FILE CSS */

.HprHir {
  /* padding: 0.5rem 1rem; */
  /* background: #e2edfd; */
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  border-radius: 50px;
  box-shadow: 0px 1px 4px 0px #00000040;
  width: 424px;
  border: 1px solid #dfdfdf;
}

.HprHir>.activeSection {
  background-color: #52447d;
  color: #fff;
  width: 212px;
  height: 45px;
  border-radius: 28.5px;
}

.ABDMImg {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.Pargtext {
  text-align: left;
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.health-service {
  font-family: Outfit;
  font-size: 22px;
  font-weight: 500;
  line-height: 27.72px;
  text-align: center;
  color: #52447d;
}

.abdm-input-feild {
  width: 424px;
  height: 50px;
  border-radius: 15px;
  border: 1px;

  background-color: #fbf9ff;
  border: 1px solid #52447d;
}

.yes-no-buttons-section {
  display: flex;
  gap: 2rem;
  cursor: pointer;
}

/* Health text in abdm */

.healthtext {
  font-family: Varela Round;
  font-size: 21px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.1rem;
  text-align: center;
  padding: 0.75rem;
}

/* Update Education CSS */

.headerEdu {
  padding: 0.5rem 1rem;
  border-radius: 7px;
  font-family: Vazirmatn;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.15em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.educationDetails {
  color: #52447d;
  font-family: Vazirmatn;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.1125rem;
  border-radius: 12px;
  border: 1px solid #52447d;
  width: 25vw;
  padding: 0.5rem 1rem;
}

.topContainer {
  display: flex;
  gap: 4rem;
  align-items: center;
}

.regp-topContainer {
  display: flex;
  gap: 4rem;
  align-items: center;
  padding: 0 3rem;
  margin-bottom: 1.5rem;
}

.educationCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 9vh;
  border-radius: 12px;
  border: 1px solid #acc5ea;
  background-color: #e0edff;
}

.Addeducation {
  color: #52447d;
  font-size: 14px;
  font-family: Verdana;
  font-weight: 400;
  line-height: 18.38px;
  letter-spacing: 15%;
}

.cardsCols,
.regEducards-cardsCols {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  gap: 2%;
  margin-bottom: 5%;
}

.regEducards-cardsCols {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  gap: 2%;
  /* height: 57vh;
  overflow-y: auto; */
  margin-right: 10px;
  padding-right: 3px;
}

.Specialitybox {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  width: 100%;
}

.Btn {
  width: 90%;
  height: 45px;
}

/* for hospital education exp */
.Boxshadow {
  /* position:fixed; */
  /* top:10%; */
  /* left:20%; */
  margin: 0 auto;
  border-radius: 1.5rem;
  box-shadow: 0px 0px 8px 0px rgba(47, 155, 255, 0.34);
  background-color: #fff;
  width: 900px;
  margin-top: 60px;
}

.another-Boxshadow {
  /* position:fixed; */
  /* top:30%;
  left:20%; */
  margin: 0 auto;
  border-radius: 1.5rem;
  box-shadow: 0px 0px 8px 0px rgba(47, 155, 255, 0.34);
  background-color: #fff;
  width: 900px;
  margin-top: 9%;
}

.Updbtn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
  align-items: center;
}

.profile-bottomContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

/* Education details card container*/

.educationd-bottomContainer {
  padding-left: 10px;
  width: 100%;
  padding-top: 10px;
  padding-right: 10px;
}

.educationd-cardImg>img {
  width: 80px;
  height: 90px;
}

.educationd-cardContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  font-size: 1.1rem;
  gap: 5px;
  height: 307px;
  overflow-y: auto;
  overflow-x: hidden;
  /* border: 1px solid #00000026; */
  border-radius: 10px;
  box-shadow: 0px 2px 10px -2px #52447d33;
}

.experianced-cardContainer {
  height: 350px !important;
}

.educationd-cardContainer:hover {
  background-color: #f9fbff;
  box-shadow: 0 0 11px #00000033;
}

.educationd-cardImgContainer {
  width: 100%;
  text-align: center;
  margin-top: -3px;
}

.educationd-cardContentContainer {
  line-height: 2.5rem;
  padding-left: 10%;
}

.educationd-addContainer {
  background-color: #f7f0ff;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.educationd-add {
  font-size: 128px;
  color: #ddc3ff;
}

.educationd-inputField {
  width: 47%;
  display: flex;
  /* gap: 5px; */
  flex-direction: column;
}

.educationd-Btn {
  width: 24%;
  height: 45px;
  border-radius: 10px;
  text-align: center;
  align-content: center;
}

.educationd-close-Btn {
  background-color: #fff;
  border: 1px solid #52447d;
  cursor: pointer;
}

.educationd-save-Btn {
  background-color: #52447d;
  color: #fff;
  cursor: pointer;
}

.educationd-buttonContainer {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: flex-end;
  gap: 20px;
  align-content: center;
  height: 85px;
}


.hpr_inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.hpr_indiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.profile-input-date>.MuiFormControl-root {
  /* border: 1px solid #dfdfdf; */
  justify-content: center;
}

@media only screen and (max-width: 1024px) {
  .sectionsName p {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 770px) {
  .another-Boxshadow {
    width: 716px;
  }

  .sectionsName p {
    font-size: 0.5rem;
  }

  .Boxshadow {
    width: 96%;
  }

  .Boxwidth {
    padding: 1rem;
  }

  .css-1qa3itt-control {
    width: 100%;
  }

  .cardsCols {
    grid-template-columns: repeat(2, 1fr);
  }
}

.leftContainer .hfr_hpr_container {
  gap: 1rem;
  align-items: center;
  display: flex;
  justify-content: center;
}

.visitHistorytab {
  padding: 0 2%;
}