.headerContentWrapper {
  /* width:85%; */
  flex-grow: 1;
}

.bgAppointmentsPatientsBodyContainerAPM .AppointmentMainSection .patientsListContainer {
  overflow-x: scroll;
}

.home {
  background-color: #f6f6f6;
  padding-top: 1rem;
  min-height: 90vh;
}

.homeBody {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: space-between;
  justify-content: center;
  background-color: white !important;
  position: relative;
  z-index: 2;
  border-radius: 10px;
}

.homeBackground {
  position: absolute;
  object-fit: cover;
  bottom: 0;
  left: -65px;
  display: none;
}

.patientEachItemContainer .columnsColor {
  color: #C1C1C1 !important;

}

.homeBackground img {
  height: 85vh;
  width: 85vw;
}

.css-1hbyad5-MuiTypography-root {
  display: none;
}

.css-3jvy96-MuiTypography-root-MuiDatePickerToolbar-title {
  display: none;
}

.css-4pax7l-MuiPickersLayout-root .MuiPickersLayout-toolbar {
  display: none;
}

.css-4pax7l-MuiPickersLayout-root .MuiPickersLayout-actionBar {
  display: none;
  padding: 0;
}

.css-1q04gal-MuiDateCalendar-root {
  max-height: auto !important;
}

.css-nk89i7-MuiPickersCalendarHeader-root {
  position: relative !important;
  color: #52447d;
}

.css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon {
  color: #52447d;
}

/* Calendar Header - Month & Year */
.css-1q04gal-MuiDateCalendar-root .MuiPickersCalendarHeader-root {
  font-size: 22px !important;
  font-weight: bold;
  background-color: #6a5acd;
  color: white !important;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Equal spacing for arrows */
}

/* Arrow Buttons - Ensure Equal Spacing */
.css-1q04gal-MuiDateCalendar-root .MuiPickersCalendarHeader-root .MuiIconButton-root {
  width: 40px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
}

/* Adjust Left Arrow */
/* .css-1q04gal-MuiDateCalendar-root .MuiPickersCalendarHeader-root .MuiIconButton-root:first-child {
  margin-left: 30px !important;
} */

/* Adjust Right Arrow */
/* .css-1q04gal-MuiDateCalendar-root .MuiPickersCalendarHeader-root .MuiIconButton-root:last-child {
  margin-right: 10px !important;
} */

/* Fix Arrow Icon Color */
.css-1vooibu-MuiSvgIcon-root {
  color: #ffffff !important;
  /* White arrow color */
  font-size: 24px !important;
  /* Adjust icon size */
}


/* .css-cyfsxc-MuiPickersCalendarHeader-labelContainer {
  position: absolute;
  left: 20%; */
/* transform: translateX(-50%); */
/* } */

/* .css-9reuh9-MuiPickersArrowSwitcher-root {
  justify-content: space-between !important;
} */

/* .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
  margin-right: 298% !important;
  left: -16px;
} */

/* .css-1q04gal-MuiDateCalendar-root {
    max-height: 294px !important;
  } */

.css-i5q14k-MuiDayCalendar-header {
  gap: 13px;
}

/* Ensure the Calendar Fully Expands Inside the Container */
/* Calendar Root Container */
.css-1q04gal-MuiDateCalendar-root {
  width: 100% !important;
  min-width: 100% !important;
  height: 100% !important;
  padding: 15px;
  padding-top: 3%;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Month Header (Bold + Background) */
.css-1q04gal-MuiDateCalendar-root .MuiPickersCalendarHeader-root {
  font-size: 22px !important;
  /* Increased month font */
  font-weight: bold;
  background-color: #6a5acd;
  color: white !important;
  padding: 12px;
  border-radius: 8px;
  /* width: 80%; */
  /* padding-left: 30%; */
  /* padding-right: 5%; */
  text-align: center;
  /* margin-left: 10%; */
}

/* Weekday Headers (Bold + Equal Gap) */
.css-1q04gal-MuiDateCalendar-root .MuiDayCalendar-header {
  font-size: 18px !important;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  gap: 10px;
  /* Ensures equal spacing */
}

/* Week Container (Aligns Dates Properly Under Weekdays) */
.css-1q04gal-MuiDateCalendar-root .MuiDayCalendar-weekContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Date Styling (Bold + Same Width as Weekdays) */
.css-1q04gal-MuiDateCalendar-root .MuiPickersDay-root {
  font-size: 18px !important;
  /* Slightly reduced for better fit */
  font-weight: bold;
  width: 40x !important;
  height: 42px !important;
  margin: 0px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Selected Date (Perfect Circle) */
.css-1q04gal-MuiDateCalendar-root .Mui-selected {
  background-color: #6a5acd !important;
  color: white !important;
  font-size: 18px !important;
  /* width: 42px !important;
  height: 42px !important;
  border-radius: 50% !important; */
  /* transform: scale(1.1); */
}

/* Prevent Scroll & Ensure Proper Fitting */
.css-1q04gal-MuiDateCalendar-root .MuiDayCalendar-slideTransition,
.css-1q04gal-MuiDateCalendar-root .MuiPickersSlideTransition-root {
  overflow: hidden !important;
  height: auto !important;
}

.css-1vs7z2v-MuiYearCalendar-root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* Centers horizontally */
  align-items: center;
  /* Centers vertically */
  overflow-y: auto;
  height: 100%;
  padding: 0 4px;
  width: 320px;
  max-height: 280px;
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  /* Ensures it's centered in its parent */
}



/* Ensure Responsive Design */
/* @media screen and (max-width: 768px) {
  .css-1q04gal-MuiDateCalendar-root {
    padding: 10px;
  }

  .css-1q04gal-MuiDateCalendar-root .MuiPickersDay-root {
    width: 40px !important;
    height: 40px !important;
    font-size: 18px !important;
  }
} */


.css-flbe84-MuiDayCalendar-weekContainer {
  gap: 13px;
}

.icon {
  width: 38px;
  height: 40.18px;
}

.homeBottomContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0 100px; */
}

.appointmentsListContainer {
  height: 66vh;
  /* border: 2px solid #4affe9; */
  background: white !important;
  width: 100%;
  padding: 14px 13px;
}

.bgAppointmentsPatientsBodyContainerAPM.AppointmentMainSection .patientsListContainer .innerListContainerSection {
  border: 1px solid #D6D6D6;
  background: white !important;
  /* width: 80%; */
  display: flex;
  flex-direction: column;
  /* min-width:550px; */
  height: 515px;
  /* background-color: white; */
  background-color: #fff8ea;
  /* padding: 14px 10px; */
  border-radius: 20px;
  /* gap: 20px; */
  /* height:500px; */
}


.drugHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #8873c9;
  color: white;
  padding: 12.5px 30px;
  /* border-top-left-radius: 1px;
    border-top-right-radius: 3px; */
}





.anotherPatientEachItemView .screeningDetailView {
  background-color: #F7F0FF;
  border-radius: 20px;
  color: #52447D;
  white-space: nowrap;
  text-align: center;
  font-size: 15px;
  height: 38px;
  align-content: center;
}

.anotherPatientEachItemView .screeningDetailView:hover {
  background-color: #52447D;
  color: #FFF;
}

.ButtonbginAppList {
  padding: 10px;
  background: #F7F0FF;
  border-radius: 20px;
  color: white;
  white-space: nowrap;
  text-align: center;
}

.anotherPatientEachItemView {
  width: 25%;
  text-align: center;
  min-width: 103px;
}

.tableHeadingSection .extraStyle {
  /* background: #F7F0FF; */
  border-radius: 20px;
  white-space: nowrap;
  text-align: center;
}

.tableHeadingSection .extraStyle:hover {
  background-color: #52447D;
  color: #FFFFFF;
}

.appointments-containerPatientsList {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 10px;
  /* justify-content: space-around; */
  /* background-color: #cbfff2; */
  align-items: center;
  padding: 7px 12px;
  border-radius: 11px;
  cursor: pointer;
  border: 1px solid #EDEDED;
  margin-left: 10px;
  margin-right: 10px;
}

.displayMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.shortnameicon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.addAppointmentsButton {
  width: 210px;
  height: 50px;
  border-radius: 28.5px;
  background: linear-gradient(180deg, #6688ff 0%, #4b3e77 100%);
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.05em;
  color: white;
  border: none;
  cursor: pointer;
}


/* .addAppointmentsButton2 {
    width: 200px;
    height: 35px;
    border-radius: 28.5px;
    background-color:#52447D;
    font-size: 12px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.05em;
    color: white;
    border: none;
    cursor: pointer;
    margin-top:50px;
  } */
.link {
  color: white;
}





.notificationContainer1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.4rem;
  background-color: #f6f6f6;
  padding: 2rem 0.75rem 0.9rem 0.75rem;
  border-radius: 10px;
  margin-top: 1rem;
  font-weight: 500;
  font-size: 16px;
}

.notificationContainer2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.4rem;
  background-color: #fff5e2;
  padding: 2rem 0.75rem 0.9rem 0.75rem;
  border-radius: 10px;
  margin-top: 1rem;
  font-weight: 500;
  font-size: 16px;
}

.nurseImage {
  height: 400px;
}

/* .goodMorningText {
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    color: #52447d;
  } */

/* .moodText {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: #52447d;
  } */

.homeHeader {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .iconsContainer{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 70px;
    padding-right: 5%;
  } */

.profile {
  height: 41px;
  width: 41px;
}

.settingsContainer {
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #52447d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patientEachItemContainer {
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  width: 98%;
}

.appointments-homeBackground {
  display: none;
}

.anotherPatientEachItemView .appoinmentDate {
  color: #52447D;
}

.notificationContainer {
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #52447d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patientsListContainer .innerListContainerSection #patientFullDetails {
  width: 100%;
  border-radius: 20px;
  height: 464px;
  border: 1px solid rgba(172, 197, 234, 1);
  background-color: white;
}

#patientDetail {
  width: 100%;
}

.patientsBodyContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 70vh;
  /* background-color: #fff4f4; */
  background-color: white !important;
  /* padding: 20px 44px; */
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  box-shadow: none !important;
  overflow: scroll;
}

.homeBodyRight {
  /* box-shadow: 0px 0px 250px -23px rgba(83.30, 0, 238, 0.25); */
  border-radius: 28.5px;
  z-index: 2;
  position: relative;
}

.patientsInputField {
  width: 100%;
  background: #e3ebff;
  border: none;
  outline: none;
  height: 35px;
  padding: 10px;
  border-radius: 6px;
}

.homeBodyLeft {
  z-index: 2;
}




.ABHAHeading {
  font-family: Varela Round;
  font-size: 29px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  /* text-align: left; */
  color: #0b4876;
  text-align: center;
}

.abhaText {
  font-family: Varela Round;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  /* text-align: left; */
  text-align: center;
}

.abhacontent {
  font-family: Varela Round;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #888888;
}

.abhaRadio {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  padding: 10px 10px 10px 30px;

  font-family: Varela Round;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #0b4876;
}

.customContainer1 {
  display: flex;
  /* background: #f6f6f6; */
  border-radius: 21px;
  flex-direction: column;
  gap: 1.5rem;
  padding: 10px 20px;
}

.abhaContainer {
  box-shadow: 0px 0px 250px -23px #ffffff;
  border-radius: 39px;
  z-index: 2;
  position: relative;
  background-color: white;
  border: 1px solid #d5e2f4;
}

.abhaTtile {
  font-family: Varela Round;
  font-size: 29px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: #0b4876;
}

.home {
  width: 100%;
}

.content {
  font-family: Varela Round;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.046em;
  text-align: center;
  color: #939393;
}

.abhanoInfoInput {
  font-family: Varela Round;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #7f98ab;
  letter-spacing: 0em;
  text-align: left;
  align-items: center;
  justify-content: center;

  border: 1px solid #52447d;
  border-radius: 6px;
  width: 18vw;
  height: 6vh;
  padding: 1rem;
}

.nhaText {
  font-family: Varela Round;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #0b4876;
}

.bgAppointmentsPatientsBodyContainerAPM .AppointmentMainSection {
  /* width: 800px; */
  border-radius: 14px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  flex: 3;
  width: 70%;
}

.mainAppointmentSection .bgAppointmentsPatientsBodyContainerAPM {
  width: 100%;
  display: flex;
  /* background-color: white; */
  /* padding: 35px; */
  border-radius: 20px;
  /* height: 90vh; */
  gap: 3%;
  /* overflow: auto; */
  margin-bottom: 14px;
  min-height: 71vh;
  flex-direction: row;
  justify-content: space-between;
}

.anotherPatientEachItemView .scheduledButtonView {
  padding: 5px;
  border-radius: 10px;
  font-size: 12px;
}

.patientEachItemContainer .patientEachItemView {
  width: 25%;
  min-width: 103px;
  text-align: center;
  color: #52447D;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color:#C1C1C1;  */

}

.patientListItem .anotherpatientEachItemView {
  width: 160px;
  text-align: center;
  color: #52447D;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color:#C1C1C1;  */
}


.patientListItem .extraStyle:hover {
  background-color: #52447D;
}

.date {
  margin-left: 15px;
}

.appointmentDate {
  padding: 10px;
  background: #F7F0FF;
  border-radius: 20px;
  color: #52447D;
  white-space: nowrap;
  text-align: center;
}

.appointmentDate:hover {
  background-color: #52447D;
  color: #FFFFFF;
}

.patientEachItemContainer .appointmentsSearchContainer {
  padding: 0 10px;
  display: flex;
  border: 1px solid #BBB;
  border-radius: 8px;
  /* Ensures rounded corners */
  align-items: center;
  justify-content: space-around;
  width: 180px;
  height: 35px;
  font-size: 1rem;
  overflow: hidden;
  /* Prevents child elements from breaking the border radius */
}

.appointmentsSearchContainer .inputFeildSearch {
  border: none;
  width: 130px;
  text-align: left;
  font-size: 1rem;
  height: 100%;
  background: transparent;
  outline: none;
  border-radius: 8px;
  padding-left: 5px;
}

.appointmentsSearchContainer {
  margin-top: 9px;
}


.appointmentsSearchContainer .inputFeildSearch {
  border: none;
  width: 130px;
  text-align: left;
  font-size: 1rem;
  height: 38px !important;
}

.appointmentsSearchContainer .searchIcon {
  height: 20px;
  /* color:#C1C1C1 !important; */
}



.filteredDataSection .tableHeadingSection {
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  width: 100%;
  height: 63px;
  /* border-radius: 10px; */
  border: 1px;
  background: #FFFFFF;
  /* border: 1px solid #EDEDED; */
}

.patientEachItemContainerHeader {
  padding: 10px 25px;
  color: #c1c1c1;
  height: 60px;
}

input::placeholder {
  font-size: 16px !important;
}

textarea:focus,
input:focus {
  outline: none;
}

#patientFullDetails .patientDetailHeading {
  background-color: #EAEEFF;
  text-align: left;
  color: #52447d;
  font-family: Outfit;
  font-size: 24px;
  font-weight: 600;
  line-height: 40.24px;
  letter-spacing: 0.12em;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-left: 30px;
  display: flex;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 2;
}

.appointments-homeBody {
  width: 100%;
}

.appointments-homeBottomContainerSection {
  width: 100%;
}

.appointments-homeBodyLeft {
  width: 100%;
}

.appointments-homeBodyRight {
  width: 100%;
  margin: 0 auto;

}

@media (max-width: 1024px) {
  /* .css-1vv5unf-MuiGrid-root {
      padding: 0 !important;
    }
  
    .nurseImage {
      height: 370px !important;
    }
  
    .css-1q04gal-MuiDateCalendar-root {
      min-width: 310px !important;
      height: 220px !important;
    } */

  /* .icon {
      width: 28px !important;
      height: inherit !important;
    } */

  .CalenderHeading h2 {
    font-size: 20px;
  }

  .mainAppointmentSection .bgAppointmentsPatientsBodyContainerAPM {
    gap: 20px;
  }

  /* 
    .css-nk89i7-MuiPickersCalendarHeader-root {
      margin: 5px 0;
    }
  
    .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
      height: 25px !important;
    }
  
    .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root {
      height: 27px !important;
    }
  
    .css-jgls56-MuiButtonBase-root-MuiPickersDay-root {
      height: 27px !important;
    }
  
    .addAppointmentsButton {
      width: 150px;
      height: 30px;
      font-size: 10px;
    }
  
    .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button {
      margin-left: -34px !important;
    } */
}


@media (max-width: 960px) {
  .mainAppointmentSection .bgAppointmentsPatientsBodyContainerAPM {
    /* flex-wrap: wrap; */
  }

  .navHeader {
    display: none !important;
  }

  .home {
    /* width:100% !important; */
  }

  /* .mainAppointmentSection .bgAppointmentsPatientsBodyContainerAPM{
        justify-content: flex-start !important;
      } */
}


@media (max-width: 768px) {
  .goodMorningText {
    font-size: 20px !important;
  }

  .goodMorningTextContainer {
    display: inline;
    width: 50%;
  }

  .navHeader {
    display: none !important;
  }

  .home {
    width: 100% !important;
  }

  .patientEachItemContainer .appointmentsSearchContainer {
    height: 46px !important;
    width: 35%;
  }

  .mainAppointmentSection .bgAppointmentsPatientsBodyContainerAPM {
    padding: 1px !important;
    gap: 20px;
  }

  .bgAppointmentsPatientsBodyContainerAPM .AppointmentMainSection {
    width: 90% !important;
  }

  .bgAppointmentsPatientsBodyContainerAPM .AppointmentMainSection .patientsListContainer .innerListContainerSection {
    overflow-x: scroll;
  }

  .appointmentsSearchContainer .inputFeildSearch {
    width: 75%;
  }
}

.mainAppointmentSection {
  /* height: 83vh; */
  display: flex;
  align-content: center;
  align-items: center;
}

.filteredDataSection {
  width: 96%;
  margin: 0 auto;
}

/* 
  {
    display: flex;
    padding: 1%;
    align-items: center;
    justify-content: center;
    margin: 0 7%; 
  }
  {
    display: flex;
      align-items: center;
  } */