.initalAssessmentSubHeading {
    background: rgba(255, 247, 235, 1);
    padding: 0.7rem 1rem;
    margin: 2%;
    border-radius: 10px;
}

.AssessmentExamination {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-left: 3%;
    margin-right: 3%;
}

.multisymptoms {}